import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Button,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider } from "@mui/material";
import LogoImg from "./logo";
import sports from "../img/sports.png";
import entertainment from "../img/entertainment.png";
import technology from "../img/technology.png";
import health from "../img/health.png";
import business from "../img/business.png";
import education from "../img/education.png";
import food from "../img/food.png";
import life from "../img/life.png";
import causes from "../img/causes.png";
import family from "../img/family.png";
import nfts from "../img/nfts.png";
import virtualreality from "../img/virtualreality.png";
import diy from "../img/diy.png";
import photography from "../img/photography.png";
import astrology from "../img/astrology.png";
import { useNavigate } from "react-router-dom";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { usersEnum } from "../config/enum";
import { addSession } from "../redux/slice/session";
import { useForm } from "react-hook-form";
import { checkUserProfile } from "../redux/thunk/session";
import { useUserProfile } from "../context/profile.contex";

export default function InterestsForm() {
  const { handleSubmit } = useForm();
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [showOtherInput, setShowOtherInput] = useState({});
  const [customInterest, setCustomInterest] = useState({});
  const [otherInput, setOtherInput] = useState(false);
  const navigate = useNavigate();

  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();

  const handleSelect = (interest, category) => {
    if (interest === "Other") {
      // Toggle the 'Other' input visibility
      setOtherInput((prev) => !prev);
      setShowOtherInput((prev) => ({
        ...prev,
        [category]: !prev[category],
      }));
    } else {
      // Toggle the selection for the interest
      setSelectedInterests((prev) =>
        prev.includes(interest)
          ? prev.filter((i) => i !== interest)
          : [...prev, interest]
      );
    }
  };

  const handleCustomInterestChange = (event, category) => {
    setCustomInterest({
      ...customInterest,
      [category]: event.target.value,
    });
  };

  const onSubmit = async (data) => {
    // try {
    //     const response = await networkRequest("patch", apiUrl.USER_PROFILE, {
    //         ...data,
    //         userId: user?.userId,
    //         type: usersEnum.NORMAL,
    //         interests:selectedInterests,
    //     });

    //     console.log("response.data", response.data)

    //     if (response) {
    //         dispatch(addSession(response.data));
    navigate("/socialmedia");
    //     }
    // } catch (error) {
    //     console.error("Error signing up:", error);
    // }
  };

  const categories = [
    {
      title: "Sports",
      label: "Recommend relevant content of sports",
      interests: [
        "Cricket",
        "Archery",
        "Basketball",
        "Football",
        "Fencing",
        "Table Tennis",
        "Hockey",
        "Badminton",
        "Boxing",
        "Chess",
        "Other",
      ],
      icon: sports,
    },
    {
      title: "Entertainment",
      label: "Recommend relevant content of sports",
      interests: [
        "Movies",
        "Music",
        "Theater",
        "Comedy",
        "Dance",
        "Magic Shows",
        "Other",
      ],
      icon: entertainment,
    },
    {
      title: "Technology & Innovation",
      label: "Recommend relevant content of sports",
      interests: [
        "Gadgets & Devices",
        "Programing & Software Devlopment",
        "AI & Machine Learning",
        "Blockchain & Cryptocurrency",
        "Startups & Entrepreneurship",
        "Other",
      ],
      icon: technology,
    },
    {
      title: "Health & Wellness",
      label: "Recommend relevant content of sports",
      interests: [
        "Mental Health",
        "Nutrition & Dieting",
        "Exercise & Fitness",
        "Alternative Medicine & Holistic Health",
        "Other",
      ],
      icon: health,
    },
    {
      title: "Business & Finance",
      label: "Recommend relevant content of sports",
      interests: [
        "Investing",
        "Entrepreneurship",
        "Personal Finance",
        "Marketing",
        "Other",
      ],
      icon: business,
    },
    {
      title: "Education & Career Development",
      label: "Recommend relevant content of sports",
      interests: [
        "Skill Development",
        "Professional Networking",
        "Career Guidance & Job Search",
        "Online Courses & Certifications",
        "Other",
      ],
      icon: education,
    },
    {
      title: "Lifestyle & fashion",
      label: "Recommend relevant content of sports",
      interests: [
        "Fashion & Style",
        "Beauty & Skincare",
        "Home & Interior Design",
        "Sustainable Living & Minimalism",
        "Other",
      ],
      icon: life,
    },
    {
      title: "Food & Drink",
      label: "Recommend relevant content of sports",
      interests: [
        "Cooking & Recipes",
        "Dining Out & Restaurant Reviews",
        "Wine & Craft Beer",
        "Health & Diet Foods",
        "Other",
      ],
      icon: food,
    },
    {
      title: "Causes & Social Impact",
      label: "Recommend relevant content of sports",
      interests: [
        "Environmental Sustainability ",
        "Social Justice & Activism",
        "Charities & Non-Profit Initiatives",
        "Volunteering Opportunities",
        "Other",
      ],
      icon: causes,
    },
    {
      title: "Family & Relationships",
      label: "Recommend relevant content of sports",
      interests: [
        "Parenting & Family Life",
        "Relationships & Dating Advice",
        "Mental Wellness & Relationships",
        "Other",
      ],
      icon: family,
    },
    {
      title: "NFTs & Digital Art",
      label: "Recommend relevant content of sports",
      interests: [],
      icon: nfts,
    },
    {
      title: "Virtual Reality & Augmented Reality",
      label: "Recommend relevant content of sports",
      interests: [],
      icon: virtualreality,
    },
    {
      title: "DIY Projects",
      label: "Recommend relevant content of sports",
      interests: [],
      icon: diy,
    },
    {
      title: "Photography & Videography",
      label: "Recommend relevant content of sports",
      interests: [],
      icon: photography,
    },
    {
      title: "Astrology & Mysticism",
      label: "Recommend relevant content of sports",
      interests: [],
      icon: astrology,
    },
  ];

  return (
    <>
      <div className="container">
        <LogoImg />
        <div>
          <div>
            <div className="form">
              <Box sx={{ textAlign: "center", p: 4 }}>
                <Typography
                  variant="h1"
                  fontWeight="bold"
                  gutterBottom
                  sx={{
                    fontWeight: "700",
                    fontSize: "45px",
                    fontFamily: "poppins, semiBold",
                  }}
                >
                  Tell us What you’re interested in
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    fontFamily: "poppins",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                >
                  This platform helps to recommend relevant content.
                </Typography>

                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="interest-catagory"
                >
                  {categories.map((category, index) => (
                    <Accordion key={index} sx={{ borderRadius: "10px", mt: 2 }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* Display the icon image */}
                          <img
                            src={category.icon}
                            alt={`${category.title} icon`}
                            style={{
                              width: "37px",
                              height: "37px",
                              marginRight: "10px",
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontFamily: "poppins",
                                fontWeight: "500",
                                fontSize: "14px",
                              }}
                            >
                              {category.title}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontFamily: "poppins",
                                fontWeight: "400",
                                fontSize: "11px",
                              }}
                            >
                              {category.label}
                            </Typography>
                          </div>
                        </div>
                      </AccordionSummary>

                      <Divider
                        sx={{
                          borderColor: "lightgray",
                          width: "95%",
                          ml: 3,
                          mb: 2,
                        }}
                      />

                      <AccordionDetails>
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                          {category.interests.map((interest) => (
                            <div
                              key={interest}
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Chip
                                // key={interest}
                                title={interest}
                                label={interest}
                                onClick={() => handleSelect(interest)}
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: selectedInterests.includes(
                                    interest
                                  )
                                    ? "lightgreen"
                                    : "white",
                                  border: "1px solid lightgreen",
                                }}
                              />
                            </div>
                          ))}
                          {otherInput && (
                            <TextField
                              size="small"
                              variant="outlined"
                              placeholder="Type your interest"
                              value={customInterest[category.title] || ""}
                              onChange={(event) =>
                                handleCustomInterestChange(
                                  event,
                                  category.title
                                )
                              }
                              sx={{ ml: 2 }}
                            />
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      mt: 4,
                      px: 6,
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                    }}
                  >
                    Done
                  </Button>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
