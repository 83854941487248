import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  MenuItem,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Checkbox,
  Link,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "../css/signupform.css";
import LogoImg from "./logo";
import signup from "../img/signup.png";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { usersEnum } from "../config/enum";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addSession } from "../redux/slice/session";
import { useUserProfile } from "../context/profile.contex";

export default function SignUpForm() {
  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [accountType, setAccountType] = useState(""); // Track the selected account type
  const [businessType, setBusinessType] = useState(""); // Track the selected business type
  const [otpSent, setOtpSent] = useState(false); // Track if OTP is sent
  const [otp, setOtp] = useState(""); // Store OTP input
  const [email, setEmail] = useState(""); // Store email for OTP generation
  const [userName, setUserName] = useState("");
  const [fullName, setFullName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [password, setPassword] = useState("");
  const [otpVerified, setOtpVerified] = useState(false); // Track OTP verification status
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(60);
  const isMount = useRef(false);

  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();

  // Handle account type selection from dropdown
  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value); // Update the selected account type
    resetForm(); // Reset the form and OTP process
  };

  // Handle Business type selection from dropdown
  const handleBusinessTypeSelect = (event) => {
    setBusinessType(event.target.value); // Update the selected business type
  };

  // Function to reset form and OTP state
  const resetForm = () => {
    reset(); // Clear all form fields
    setOtpSent(false); // Reset OTP state
    setOtpVerified(false); // Reset OTP verified state
    setOtp(""); // Clear OTP input
    setEmail(""); // Clear email input
  };

  function handleStateChange(e) {
    const value = e.target.value || "";
    switch (e.target.name) {
      case "fullName":
        setFullName(value);
        break;
      case "userName":
        setUserName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "businessAddress":
        setBusinessAddress(value);
        break;
      case "businessName":
        setBusinessName(value);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    let timer;
    if (otpSent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setOtpSent(false);
    }

    return () => clearInterval(timer);
  }, [timeLeft, otpSent]);

  const generateOtp = async () => {
    try {
      if (otpSent) {
        return toast("otp already sent");
      }
      const response = await networkRequest("post", apiUrl.SIGN_UP_OTP, {
        email,
      });
      if (response.statusCode !== 200) {
        return toast("something went wrong!!");
      }
      setOtpSent(true);
      setTimeLeft(60);
      return;
    } catch (error) {
      setOtpSent(false);
    }
  };

  const signUpUser = async () => {
    if (accountType === usersEnum.NORMAL) {
      const response = await networkRequest("post", apiUrl.SIGN_UP, {
        type: accountType,
        userName: userName,
        fullName: fullName,
        email: email,
        password: password,
      });

      if (!response || !response?.data?.sessions[0]?.token) {
        return navigate("/signup");
      }

      localStorage.setItem("token", response?.data?.sessions[0]?.token);

      setUserProfile(response?.data);

      return navigate("/individualsignup");
    } else if (accountType === usersEnum.BUSINESS) {
      const response = await networkRequest("post", apiUrl.SIGN_UP, {
        type: accountType,
        userName: userName,
        businessName: businessName,
        email: email,
        password: password,
        address: businessAddress,
      });

      if (!response || !response?.data?.sessions[0]?.token) {
        return navigate("/signup");
      }

      localStorage.setItem("token", response?.data?.sessions[0]?.token);

      return navigate("/buisnesssignup");
    }
  };

  const verifyOtp = async () => {
    const response = await networkRequest("patch", apiUrl.OTP_VERIFY, {
      email: email,
      otp: otp,
    });

    if (!response) {
      navigate("/signup");
    }

    setOtpVerified(true);

    await signUpUser();
  };

  // const businessAddress = (data) => {
  //   if (accountType === usersEnum.NORMAL) {
  //     setEmail(data.email);
  //     setUserName(data.userName);
  //     setFullName(data.fullName);
  //     setPassword(data.password);
  //   } else if (accountType === usersEnum.BUSINESS) {
  //     setEmail(data.email);
  //     setUserName(data.userName);
  //     setBusinessName(data.businessName);
  //     setPassword(data.password);
  //     setBusinessAddress(data.businessAddress);
  //   }
  // };

  // Render form fields based on selected account type
  const renderFormFields = () => {
    if (accountType === usersEnum.NORMAL) {
      return (
        <>
          <TextField
            fullWidth
            label="Full Name"
            required
            name="fullName"
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.fullName}
            helperText={errors.fullName ? errors.fullName.message : ""}
          />
          <TextField
            fullWidth
            label="User Name"
            required
            name="userName"
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.userName}
            helperText={errors.userName ? errors.userName.message : ""}
          />
          <TextField
            fullWidth
            label="Email Address"
            type="email"
            name="email"
            required
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
          />
          <TextField
            fullWidth
            label="Password"
            type="password"
            required
            name="password"
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ""}
          />
          <Checkbox
            required
            sx={{ "& .MuiInputLabel-asterisk": { color: "red" } }}
          />
          <Typography
            variant="body2"
            required
            sx={{ float: "right", mr: 17, mt: 1.25 }}
          >
            By signing up, I agree to the{" "}
            <Link
              href="#"
              sx={{
                fontSize: 14,
                fontFamily: "poppins",
                fontWeight: 600,
                color: "#9A00A9",
                textDecoration: "none",
              }}
            >
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link
              href="#"
              sx={{
                fontSize: 14,
                fontFamily: "poppins",
                fontWeight: 600,
                color: "#9A00A9",
                textDecoration: "none",
              }}
            >
              Privacy Policy
            </Link>
            .
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2, borderRadius: "30px", height: "50px" }}
            onClick={generateOtp}
            style={{
              background:
                "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
            }}
          >
            Get 4-Digit Code
          </Button>

          {otpSent && (
            <Box sx={{ mt: 3 }}>
              <TextField
                fullWidth
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: "30px" },
                }}
              />
              <Button
                variant="contained"
                onClick={verifyOtp}
                fullWidth
                sx={{
                  borderRadius: "30px",
                  height: "50px",
                  background:
                    "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                }}
              >
                Verify OTP
              </Button>
              {otpVerified && (
                <Typography variant="body2" sx={{ mt: 2, color: "green" }}>
                  OTP Verified!
                </Typography>
              )}
              <Typography variant="body2" sx={{ mt: 2, color: "green" }}>
                {timeLeft}
              </Typography>
            </Box>
          )}
        </>
      );
    } else if (accountType === usersEnum.BUSINESS) {
      return (
        <>
          {/* <form action="" onSubmit={handleSubmit(onSubmit)}> */}
          <TextField
            fullWidth
            label="User Name"
            name="userName"
            required
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.userName}
            helperText={errors.userName ? errors.userName.message : ""}
          />

          <TextField
            fullWidth
            label="Business Name"
            required
            name="businessName"
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.businessName}
            helperText={errors.businessName ? errors.businessName.message : ""}
          />
          <TextField
            fullWidth
            label="Business Email"
            type="email"
            name="email"
            required
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ""}
          />
          <TextField
            fullWidth
            label="Business Address"
            required
            name="businessAddress"
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            onChange={handleStateChange}
            error={!!errors.businessAddress}
            helperText={
              errors.businessAddress ? errors.businessAddress.message : ""
            }
          />

          <TextField
            fullWidth
            label="Password"
            type="password"
            name="password"
            onChange={handleStateChange}
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": { borderRadius: "30px" },
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
            error={!!errors.password}
            helperText={errors.password ? errors.password.message : ""}
          />

          {/* Business Type Dropdown */}
          <FormControl
            fullWidth
            Label="Business Category"
            required
            sx={{
              mb: 2,
              "& .MuiInputLabel-asterisk": { color: "red" },
            }}
          >
            <InputLabel id="business-type-label">Business Category</InputLabel>
            <Select
              labelId="business-type-label"
              value={businessType}
              onChange={handleBusinessTypeSelect}
              label="Select Business Type"
              sx={{ borderRadius: "30px" }}
            >
              {/* Add your business category options here */}
              <MenuItem value="Retail">Retail</MenuItem>
              <MenuItem value="Service">Service</MenuItem>
              <MenuItem value="Manufacturing">Manufacturing</MenuItem>
              <MenuItem value="Hospitality">Hospitality</MenuItem>
              <MenuItem value="Health and Wellness">
                Health and Wellness
              </MenuItem>
              <MenuItem value="Finance and Insurance">
                Finance and Insurance
              </MenuItem>
              <MenuItem value="Education">Education</MenuItem>
              <MenuItem value="Technology">Technology</MenuItem>
              <MenuItem value="Real Estate">Real Estate</MenuItem>
              <MenuItem value="Transportation and Logistics">
                Transportation and Logistics
              </MenuItem>
              <MenuItem value="Non-profit/Charity">Non-profit/Charity</MenuItem>
              <MenuItem value="Entertainment and Media">
                Entertainment and Media
              </MenuItem>
              <MenuItem value="Agriculture and Farming">
                Agriculture and Farming
              </MenuItem>
              <MenuItem value="Construction">Construction</MenuItem>
              <MenuItem value="Professional Services">
                Professional Services
              </MenuItem>
              <MenuItem value="Retail and Wholesale Trade">
                Retail and Wholesale Trade
              </MenuItem>
              <MenuItem value="Energy and Utilities">
                Energy and Utilities
              </MenuItem>
              <MenuItem value="Arts and Crafts">Arts and Crafts</MenuItem>
              <MenuItem value="Sports and Recreation">
                Sports and Recreation
              </MenuItem>
              <MenuItem value="Food and Beverage">Food and Beverage</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <Checkbox />
          <Typography variant="body2" sx={{ float: "right", mr: 17, mt: 1.25 }}>
            By signing up, I agree to the{" "}
            <Link
              href="#"
              sx={{
                fontSize: 14,
                fontFamily: "poppins",
                fontWeight: 600,
                color: "#9A00A9",
                textDecoration: "none",
              }}
            >
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link
              href="#"
              sx={{
                fontSize: 14,
                fontFamily: "poppins",
                fontWeight: 600,
                color: "#9A00A9",
                textDecoration: "none",
              }}
            >
              Privacy Policy
            </Link>
            .
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2, borderRadius: "30px", height: "50px" }}
            type="button"
            style={{
              background:
                "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
            }}
            onClick={generateOtp}
          >
            Get 4-Digit Code
          </Button>

          {otpSent && (
            <Box sx={{ mt: 3 }}>
              <TextField
                fullWidth
                label="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": { borderRadius: "30px" },
                }}
              />
              <Button
                variant="contained"
                onClick={verifyOtp}
                fullWidth
                sx={{
                  borderRadius: "30px",
                  height: "50px",
                  background:
                    "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                }}
              >
                Verify OTP
              </Button>
              {otpVerified && (
                <Typography variant="body2" sx={{ mt: 2, color: "green" }}>
                  OTP Verified!
                </Typography>
              )}
              <Typography variant="body2" sx={{ mt: 2, color: "green" }}>
                {timeLeft}
              </Typography>
            </Box>
          )}

          {/* </form> */}
        </>
      );
    }
    return null; // No form until an account type is selected
  };

  return (
    <>
      <div className="container">
        <LogoImg />
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="account">
              <Box
                component="form"
                onSubmit={handleSubmit()}
                sx={{ display: "flex", flexDirection: "row" }}
              >
                <Box sx={{ flex: 1, padding: "20px" }}>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                      fontWeight: "700",
                      fontSize: "45px",
                      fontFamily: "Poppins, sans-serif",
                      mb: 2,
                    }}
                  >
                    {accountType === "normal-user"
                      ? `Individual Sign Up`
                      : accountType === "business-user"
                      ? `Business Sign Up`
                      : "Sign Up"}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mb: 5,
                      fontSize: "14px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Essential Information.
                  </Typography>

                  {/* Account Type Dropdown */}
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 5, mt: 4 }}
                  >
                    <InputLabel id="account-type-label">
                      Select Account Type
                    </InputLabel>
                    <Select
                      labelId="account-type-label"
                      value={accountType}
                      onChange={handleAccountTypeChange}
                      label="Select Account Type"
                      sx={{ borderRadius: "30px" }}
                    >
                      <MenuItem value={usersEnum.NORMAL}>Individuals</MenuItem>
                      <MenuItem value={usersEnum.BUSINESS}>Business</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Render appropriate form fields */}
                  {renderFormFields()}
                </Box>
              </Box>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-img">
              <img src={signup} alt="group" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
