import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
  Link,
  Box,
} from "@mui/material";
import { useForm } from "react-hook-form";
import "../css/loginform.css";
import signup from "../img/signup.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import LogoImg from "./logo";
import website from "../img/website.png";
import whatsapp from "../img/whatsapp.png";
import facebook from "../img/facebook.png";
import google1 from "../img/google1.png";
import instagram from "../img/instagram.png";
import x from "../img/x.png";
import youtube from "../img/youtube.png";
import telegram from "../img/telegram.png";
import rumble from "../img/rumble.png";
import snapchat from "../img/snapchat.png";
import truthsocial from "../img/truthsocial.png";
import linkedin from "../img/linkdin.png";
import tiktok from "../img/tiktok.png";
import threads from "../img/threads.png";
import parler from "../img/Parler.png";
import reddit from "../img/reddit.png";
import discord from "../img/Discord.png";
import gettr from "../img/gettr.png";
import mastodon from "../img/Mastodon.png";
import pinterest from "../img/Pinterest.png";
import bereal from "../img/BeReal.png";
import dustbin from "../img/dustbin.png";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { useDispatch, useSelector } from "react-redux";
import { addSession } from "../redux/slice/session";
import { usersEnum } from "../config/enum";
import { useUserProfile } from "../context/profile.contex";

export default function IndividualSignupForm() {
  const { handleSubmit, register } = useForm();
  const navigate = useNavigate();

  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();

  console.log("useruseruser", user);

  // State to manage focus and content of each input field
  const [fields, setFields] = useState({
    website: { isFocused: false, hasContent: false },
    whatsapp: { isFocused: false, hasContent: false },
    facebook: { isFocused: false, hasContent: false },
    google: { isFocused: false, hasContent: false },
    instagram: { isFocused: false, hasContent: false },
    x: { isFocused: false, hasContent: false },
    linkedin: { isFocused: false, hasContent: false },
    tiktok: { isFocused: false, hasContent: false },
    snapchat: { isFocused: false, hasContent: false },
    youtube: { isFocused: false, hasContent: false },
    threads: { isFocused: false, hasContent: false },
    rumble: { isFocused: false, hasContent: false },
    parler: { isFocused: false, hasContent: false },
    reddit: { isFocused: false, hasContent: false },
    discord: { isFocused: false, hasContent: false },
    truthsocial: { isFocused: false, hasContent: false },
    gettr: { isFocused: false, hasContent: false },
    mastodon: { isFocused: false, hasContent: false },
    bereal: { isFocused: false, hasContent: false },
    pinterest: { isFocused: false, hasContent: false },
    telegram: { isFocused: false, hasContent: false },
  });

  // Handle input focus and blur for a specific field
  const handleFocus = (field) => {
    setFields((prev) => ({
      ...prev,
      [field]: { ...prev[field], isFocused: true },
    }));
  };

  const handleBlur = (field, event) => {
    setFields((prev) => ({
      ...prev,
      [field]: { isFocused: false, hasContent: !!event.target.value },
    }));
  };

  const handleNextClick = () => {
    navigate("/your-intereste");
  };

  const onSubmit = async (data) => {
    try {
      const socialLinks = [];
      for (const link of Object.values(data)) {
        if (!link) continue;
        socialLinks.push(link);
      }

      const response = await networkRequest("patch", apiUrl.USER_PROFILE, {
        type: usersEnum.NORMAL,
        userId: user?.userId,
        socialLinks,
      });

      console.log("response.data", response.data);

      if (response) {
        setUserProfile(response.data);

        // Check account type and navigate accordingly
        if (response.data.type === "normal-user") {
          navigate("/your-intereste");
        } else if (response.data.type === "business-user") {
          navigate("/businesssocial");
        }
      }
    } catch (error) {
      console.error("Error socials:", error);
    }
  };

  return (
    <>
      <div className="container">
        <LogoImg />
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="form">
              <Box sx={{ justifyContent: "center", alignItems: "center" }}>
                <div className="title mb-5 py-5">
                  <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                      fontWeight: "700",
                      fontSize: "45px",
                      fontFamily: "poppins, semiBold",
                    }}
                  >
                    Connect your Socials
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ mb: 3, fontFamily: "poppins", fontSize: "14px" }}
                  >
                    Before you get Started, Connect your Socials accounts.
                  </Typography>
                </div>

                {/* Information */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div
                    style={{
                      maxHeight: "400px",
                      overflowY: "auto",
                      paddingRight: "15px",
                    }}
                  >
                    <div
                      className="social-media-links"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        width: "550px",
                        marginTop: "5px",
                        height: "342px",
                        marginBottom: "10px",
                      }}
                    >
                      {/* Website Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.website.isFocused ||
                            fields.website.hasContent
                              ? "Website Link"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("website")}
                          onBlur={(event) => handleBlur("website", event)}
                          {...register("website")}
                          InputProps={{
                            startAdornment: !fields.website.isFocused &&
                              !fields.website.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={website}
                                    alt="Website Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Website Link</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Whatsapp Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.whatsapp.isFocused ||
                            fields.whatsapp.hasContent
                              ? "WhatsApp"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("whatsapp")}
                          onBlur={(event) => handleBlur("whatsapp", event)}
                          {...register("whatsapp")}
                          InputProps={{
                            startAdornment: !fields.whatsapp.isFocused &&
                              !fields.whatsapp.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={whatsapp}
                                    alt="WhatsApp Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>WhatsApp</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Facebook Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.facebook.isFocused ||
                            fields.facebook.hasContent
                              ? "Facebook"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("facebook")}
                          onBlur={(event) => handleBlur("facebook", event)}
                          {...register("facebook")}
                          InputProps={{
                            startAdornment: !fields.facebook.isFocused &&
                              !fields.facebook.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={facebook}
                                    alt="Facebook Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Facebook</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Google Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.google.isFocused || fields.google.hasContent
                              ? "Google"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("google")}
                          onBlur={(event) => handleBlur("google", event)}
                          {...register("google")}
                          InputProps={{
                            startAdornment: !fields.google.isFocused &&
                              !fields.google.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={google1}
                                    alt="Google Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Google</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Instagram Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.instagram.isFocused ||
                            fields.instagram.hasContent
                              ? "Instagram"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("instagram")}
                          onBlur={(event) => handleBlur("instagram", event)}
                          {...register("instagram")}
                          InputProps={{
                            startAdornment: !fields.instagram.isFocused &&
                              !fields.instagram.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={instagram}
                                    alt="Instagram Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Instagram</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* X Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.x.isFocused || fields.x.hasContent ? "X" : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("x")}
                          onBlur={(event) => handleBlur("Xx", event)}
                          {...register("X")}
                          InputProps={{
                            startAdornment: !fields.x.isFocused &&
                              !fields.x.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={x}
                                    alt="X Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>X</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Linkedin Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.linkedin.isFocused ||
                            fields.linkedin.hasContent
                              ? "Linkedin"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("linkedin")}
                          onBlur={(event) => handleBlur("linkedin", event)}
                          {...register("linkdin")}
                          InputProps={{
                            startAdornment: !fields.linkedin.isFocused &&
                              !fields.linkedin.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={linkedin}
                                    alt="Linkedin Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Linkedin</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* TikTok Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.tiktok.isFocused || fields.tiktok.hasContent
                              ? "TikTok"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("tiktok")}
                          onBlur={(event) => handleBlur("tiktok", event)}
                          {...register("tiktok")}
                          InputProps={{
                            startAdornment: !fields.tiktok.isFocused &&
                              !fields.tiktok.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={tiktok}
                                    alt="TikTok Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>TikTok</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Snapchat Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.snapchat.isFocused ||
                            fields.snapchat.hasContent
                              ? "Snapchat"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("snapchat")}
                          onBlur={(event) => handleBlur("snapchat", event)}
                          {...register("snapchat")}
                          InputProps={{
                            startAdornment: !fields.snapchat.isFocused &&
                              !fields.snapchat.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={snapchat}
                                    alt="Snapchat Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Snapchat</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* YouTube Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.youtube.isFocused ||
                            fields.youtube.hasContent
                              ? "YouTube"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("youtube")}
                          onBlur={(event) => handleBlur("youtube", event)}
                          {...register("youtube")}
                          InputProps={{
                            startAdornment: !fields.youtube.isFocused &&
                              !fields.youtube.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={youtube}
                                    alt="Instagram Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>YouTube</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Threads Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.threads.isFocused ||
                            fields.threads.hasContent
                              ? "Threads"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("threads")}
                          onBlur={(event) => handleBlur("threads", event)}
                          {...register("threads")}
                          InputProps={{
                            startAdornment: !fields.threads.isFocused &&
                              !fields.threads.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={threads}
                                    alt="Threads Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Threads</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Rumble Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.rumble.isFocused || fields.rumble.hasContent
                              ? "Rumble"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("rumble")}
                          onBlur={(event) => handleBlur("rumble", event)}
                          {...register("rumble")}
                          InputProps={{
                            startAdornment: !fields.rumble.isFocused &&
                              !fields.rumble.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={rumble}
                                    alt="Rumble Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Rumble</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Parler Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.parler.isFocused || fields.parler.hasContent
                              ? "Parler"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("parler")}
                          onBlur={(event) => handleBlur("parler", event)}
                          {...register("parler")}
                          InputProps={{
                            startAdornment: !fields.parler.isFocused &&
                              !fields.parler.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={parler}
                                    alt="Parler Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Parler</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Reddit Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.reddit.isFocused || fields.reddit.hasContent
                              ? "Reddit"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("reddit")}
                          onBlur={(event) => handleBlur("reddit", event)}
                          {...register("reddit")}
                          InputProps={{
                            startAdornment: !fields.reddit.isFocused &&
                              !fields.reddit.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={reddit}
                                    alt="Reddit Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Reddit</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Discord Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.discord.isFocused ||
                            fields.discord.hasContent
                              ? "Discord"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("discord")}
                          onBlur={(event) => handleBlur("discord", event)}
                          {...register("discord")}
                          InputProps={{
                            startAdornment: !fields.discord.isFocused &&
                              !fields.discord.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={discord}
                                    alt="Discord Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Discord</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Truth Social Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.truthsocial.isFocused ||
                            fields.truthsocial.hasContent
                              ? "Truth Social"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("truth social")}
                          onBlur={(event) => handleBlur("truth social", event)}
                          {...register("truth social")}
                          InputProps={{
                            startAdornment: !fields.truthsocial.isFocused &&
                              !fields.truthsocial.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={truthsocial}
                                    alt="Truth Social Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Truth Social</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Gettr Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.gettr.isFocused || fields.gettr.hasContent
                              ? "Gettr"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("gettr")}
                          onBlur={(event) => handleBlur("gettr", event)}
                          {...register("gettr")}
                          InputProps={{
                            startAdornment: !fields.gettr.isFocused &&
                              !fields.gettr.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={gettr}
                                    alt="Gettr Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Gettr</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Mastodon Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.mastodon.isFocused ||
                            fields.mastodon.hasContent
                              ? "Mastodon"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("mastodon")}
                          onBlur={(event) => handleBlur("mastodon", event)}
                          {...register("mastodon")}
                          InputProps={{
                            startAdornment: !fields.mastodon.isFocused &&
                              !fields.mastodon.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={mastodon}
                                    alt="Mastodon Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Mastodon</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* BeReal Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.bereal.isFocused || fields.bereal.hasContent
                              ? "BeReal"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("bereal")}
                          onBlur={(event) => handleBlur("bereal", event)}
                          {...register("bereal")}
                          InputProps={{
                            startAdornment: !fields.bereal.isFocused &&
                              !fields.bereal.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={bereal}
                                    alt="BeReal Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>BeReal</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Pinterest Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.pinterest.isFocused ||
                            fields.pinterest.hasContent
                              ? "Pinterest"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("pinterest")}
                          onBlur={(event) => handleBlur("pinterest", event)}
                          {...register("pinterest")}
                          InputProps={{
                            startAdornment: !fields.pinterest.isFocused &&
                              !fields.pinterest.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={pinterest}
                                    alt="Pinterest Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Pinterest</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>

                      {/* Telegram Field */}
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <TextField
                          label={
                            fields.telegram.isFocused ||
                            fields.telegram.hasContent
                              ? "Telegram"
                              : ""
                          }
                          variant="outlined"
                          onFocus={() => handleFocus("telegram")}
                          onBlur={(event) => handleBlur("telegram", event)}
                          {...register("telegram")}
                          InputProps={{
                            startAdornment: !fields.telegram.isFocused &&
                              !fields.telegram.hasContent && (
                                <InputAdornment position="start">
                                  <img
                                    src={telegram}
                                    alt="Telegram Icon"
                                    style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: "8px",
                                    }}
                                  />
                                  <span>Telegram</span>
                                </InputAdornment>
                              ),
                            style: {
                              borderRadius: "30px",
                              width: "450px",
                              backgroundColor: "#F9F9F9",
                            },
                          }}
                          sx={{
                            transition: "all 0.3s ease",
                          }}
                        />
                        <img
                          src={dustbin}
                          alt="Dustbin icon"
                          style={{ width: 30, height: 30, marginRight: "10px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 3,
                      mt: 2,
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Link
                      href="/socials"
                      onClick={handleNextClick}
                      sx={{
                        fontSize: 14,
                        fontFamily: "poppins",
                        fontWeight: 600,
                        color: "#9A00A9",
                        textDecoration: "none",
                      }}
                    >
                      Skip for Now
                    </Link>
                  </Box>

                  {/* Submit Button */}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mb: 2 }}
                    style={{
                      borderRadius: "30px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "600",
                      fontSize: "18px",
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                    }}
                    type="submit"
                  >
                    Continue
                  </Button>
                </form>
              </Box>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-img ">
              <img src={signup} alt="signup" style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
