import React, { useRef, useState } from "react";
import {
  Avatar,
  Button,
  IconButton,
  Typography,
  Link,
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Box,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MoreHoriz } from "@mui/icons-material";
import "../css/profilepage.css";
import NavBar from "./navbar";
import LeftSide from "./leftside";
import RightSide from "./rightside";
import Container from "react-bootstrap/Container";
import camera from "../img/camera.png";
import calander from "../img/calander.png";
import editprofile from "../img/editprofile.png";
import add from "../img/add.png";
import facebook from "../img/facebook.png";
import google1 from "../img/google1.png";
import likes from "../img/likes.png";
import comment from "../img/comment.png";
import report from "../img/report.png";
import upload from "../img/upload.png";
import save from "../img/save.png";
import locations from "../img/location.png";
import cacke from "../img/cacke.png";
import createposts from "../img/createposts.png";
import arrow from "../img/arrow.png";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { useEffect } from "react";
import { usersEnum } from "../config/enum";
import likess from "../img/likess.png";
import Zoom from "react-medium-image-zoom";
import CommentsModal from "./comments-modal";
import { useForm } from "react-hook-form";
import { useUserProfile } from "../context/profile.contex";
import website from "../img/website.png";
import whatsapp from "../img/whatsapp.png";
import instagram from "../img/instagram.png";
import x from "../img/x.png";
import youtube from "../img/youtube.png";
import telegram from "../img/telegram.png";
import rumble from "../img/rumble.png";
import snapchat from "../img/snapchat.png";
import truthsocial from "../img/truthsocial.png";
import linkedin from "../img/linkdin.png";
import tiktok from "../img/tiktok.png";
import threads from "../img/threads.png";
import parler from "../img/Parler.png";
import reddit from "../img/reddit.png";
import discord from "../img/Discord.png";
import gettr from "../img/gettr.png";
import mastodon from "../img/Mastodon.png";
import pinterest from "../img/Pinterest.png";
import bereal from "../img/BeReal.png";
import dustbin from "../img/dustbin.png";
import edit from "../img/edit.png";
import linkdin from "../img/linkdin.png";

export default function ProfilePage() {
  const { handleSubmit, register } = useForm();
  const [showMore, setShowMore] = useState(false);
  const [profile, setProfile] = useState({});
  const [createdAt, setCreatedAt] = useState(null);
  const [posts, setPosts] = useState([]);
  const [commentModal, setCommentModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [userName, setUserName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [genderType, setGenderType] = useState("");
  const [location, setLocation] = useState("");
  const { user, isLoading, error, checkUserProfile, setUserProfile } =
    useUserProfile();
  const { userId } = useParams();
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [socialLinks, setSocialLinks] = useState([]);

  const socialPlatforms = [
    { name: "website", label: "Website Link", icon: website },
    { name: "whatsapp", label: "WhatsApp", icon: whatsapp },
    { name: "facebook", label: "Facebook", icon: facebook },
    { name: "google", label: "Google", icon: google1 },
    { name: "instagram", label: "Instagram", icon: instagram },
    { name: "x", label: "X", icon: x },
    { name: "youtube", label: "Youtube", icon: youtube },
    { name: "telegram", label: "Telegram", icon: telegram },
    { name: "rumble", label: "Rumble", icon: rumble },
    { name: "snapchat", label: "Snapchat", icon: snapchat },
    { name: "truthsocial", label: "TruthSocial", icon: truthsocial },
    { name: "linkedin", label: "Linkedin", icon: linkedin },
    { name: "tiktok", label: "Tiktok", icon: tiktok },
    { name: "threads", label: "Threads", icon: threads },
    { name: "parler", label: "Parler", icon: parler },
    { name: "reddit", label: "Reddit", icon: reddit },
    { name: "discord", label: "Discord", icon: discord },
    { name: "gettr", label: "Gettr", icon: gettr },
    { name: "mastodon", label: "Mastodon", icon: mastodon },
    { name: "pinterest", label: "Pinterest", icon: pinterest },
    { name: "bereal", label: "Bereal", icon: bereal },
  ];

  const getIcon = (type) => {
    switch (type) {
      case "facebook":
        return facebook;
      case "google":
        return google1;
      case "instagram":
        return instagram;
      case "linkedin":
        return linkedin;
      case "x":
        return x;
      case "youtube":
        return youtube;
      case "telegram":
        return telegram;
      case "rumble":
        return rumble;
      case "snapchat":
        return snapchat;
      case "truthsocial":
        return truthsocial;
      case "tiktok":
        return tiktok;
      case "threads":
        return threads;
      case "parler":
        return parler;
      case "reddit":
        return reddit;
      case "discord":
        return discord;
      case "gettr":
        return gettr;
      case "mastodon":
        return mastodon;
      case "pinterest":
        return pinterest;
      case "bereal":
        return bereal;
      // Add cases for each social platform icon
      // default:
      //     return defaultIcon;
    }
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  async function getProfile() {
    const profile = await networkRequest("POST", apiUrl.USER_PROFILE, {
      userId,
    });

    setProfile(profile.data);
  }

  console.log("postssss", posts);

  async function getPosts() {
    const data = await networkRequest(
      "GET",
      apiUrl.GET_POSTS,
      {},
      {},
      { userId, ...(createdAt && { createdAt }) }
    );

    if (!data?.data?.posts) {
      return;
    }

    setPosts(data.data.posts);
    setCreatedAt(data.data.createdAt);
  }

  const toggleCommentBox = (postId) => {
    setCommentModal((prevPostId) => {
      if (!prevPostId && postId) {
        return postId;
      } else {
        return false;
      }
    });
  };

  async function manageLike(e, post) {
    if (!post?.isLiked) {
      await networkRequest("post", apiUrl.CREATE_LIKE, {
        postId: post?.postId,
      });

      setPosts((prev) => {
        const prePost = prev.find((prePost) => prePost.postId === post?.postId);
        prePost.likeCount += 1;
        prePost.isLiked = true;
        return structuredClone(prev);
      });
    } else {
      await networkRequest("delete", apiUrl.DELETE_LIKE, {
        postId: post?.postId,
      });

      setPosts((prev) => {
        const prePost = prev.find((prePost) => prePost.postId === post?.postId);
        prePost.likeCount -= 1;
        prePost.isLiked = false;
        return structuredClone(prev);
      });
    }
  }

  function onComment(postId) {
    setPosts((prev) => {
      const index = prev.findIndex((obj) => obj.postId === postId);
      const post = prev[index];
      if (!post) {
        return prev;
      }
      post.commentCount += 1;
      return structuredClone(prev);
    });
  }

  useEffect(() => {
    getProfile();
    getPosts();
  }, [userId]);

  const handleEditProfileClick = () => {
    setOpenEditModal(true);
  };

  const handleClose = () => {
    setOpenEditModal(false);
  };

  const handleOnClick = () => {
    navigate("/socialmedia");
  };

  // function handleStateChange(e) {
  //   const value = e.target.value || "";
  //   switch (e.target.name) {
  //     case "phoneNumber":
  //       setPhoneNumber(value);
  //       break;
  //     case "dateOfBirth":
  //       setDateOfBirth(value);
  //       break;
  //     case "bioData":
  //       setBioData(value);
  //       break;
  //     case "genderType":
  //       setGenderType(value);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const onSubmit = async (data) => {
    console.log("data data data", dateOfBirth);
    try {
      console.log("datadata", data);
      const response = await networkRequest("patch", apiUrl.USER_PROFILE, {
        ...data,
        userId: user.userId,
        type: usersEnum.NORMAL,
        userName: userName,
        email: emailAddress,
        dateOfBirth: dateOfBirth,
        phoneNumber: phoneNumber,
        location: location,
        gender: genderType,
      });
      if (response) {
        setUserProfile(response.data);
        navigate("/socialmedia");
      }
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const handleCoverPhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadImage(file, "coverPhoto");
    }
  };

  const uploadImage = async (file, type) => {};

  // const handleProfilePhotoChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     uploadImage(file, "profilePicture");
  //   }
  // };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    setSelectedImage(files[0]);
  };

  const handleBannerUpload = (event) => {
    const files = event.target.files;
    setSelectedBanner(files[0]);
  };

  async function changeProfile() {
    if (!selectedImage) return;
    const form = new FormData();
    form.append("profilePicture", selectedImage);
    const data = await networkRequest(
      "patch",
      apiUrl.ADD_PROFILE_PICTURE,
      form
    );
    console.log("data.user", data?.data?.user);
    const d = structuredClone(data?.data?.user);
    setProfile(d);
    await getProfile();
  }

  useEffect(() => {
    changeProfile();
  }, [selectedImage]);

  useEffect(() => {
    console.log("Profile updated:", profile);
  }, [profile]);

  async function changeBanner() {
    if (!selectedBanner) return;
    const form = new FormData();
    form.append("bannerPicture", selectedBanner);
    const data = await networkRequest(
      "patch",
      apiUrl.USER_BANNER_PICTURE,
      form
    );
    setProfile(data?.data?.user);
  }

  useEffect(() => {
    changeProfile();
  }, [selectedImage]);

  useEffect(() => {
    changeBanner();
  }, [selectedBanner]);

  // useEffect(() => {
  //   console.log("Profile updated:", profile);
  // }, [profile]);

  const handleAddSocialClick = () => {
    setOpenAddModal(true);
  };

  const [fields, setFields] = useState(
    socialPlatforms.reduce((acc, platform) => {
      acc[platform.name] = { isFocused: false, hasContent: false };
      return acc;
    }, {})
  );

  const handleFocus = (fieldName) => {
    setFields((prev) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], isFocused: true },
    }));
  };

  const handleBlur = (fieldName, event) => {
    setFields((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        isFocused: false,
        hasContent: Boolean(event.target.value),
      },
    }));
  };

  const onSave = async (data) => {
    const socialLinks = Object.keys(data).reduce((acc, key) => {
      if (data[key]) acc.push(data[key]);
      return acc;
    }, []);

    try {
      const links = [];
      for (const key in data) {
        if (data[key]) {
          links.push(data[key]);
        }
      }

      const response = await networkRequest("patch", apiUrl.USER_PROFILE, {
        type: usersEnum.NORMAL,
        userId: user?.userId,
        socialLinks: links,
      });

      if (response) {
        setUserProfile(response.data);
        setSocialLinks(links);
        navigate("/socialmedia");
      }
    } catch (error) {
      console.error("Error updating socials:", error);
    }
  };

  return (
    <>
      <NavBar />
      <Container>
        <Grid container spacing={2}>
          {/* Left Sidebar */}
          <Grid item xs={3}>
            <LeftSide />
          </Grid>

          {/* Main Profile Section */}
          <Grid item xs={6}>
            <div className="back-page mb-2">
              <div className="back-page-title">
                <Button
                  onClick={handleOnClick}
                  sx={{ width: "22px", height: "22px" }}
                >
                  <img src={arrow} alt="Arrow" style={{ width: "100%" }} />
                </Button>
                <div className="page-title">
                  <h2>{profile?.fullName || profile?.businessName}</h2>
                  {/* <p>2 Posts</p> */}
                </div>
              </div>
            </div>

            <div className="profile-section">
              {/* Header and Profile Info */}
              <div className="profile-header">
                <div className="cover-photo">
                  <img
                    src={`${profile?.bannerPicture}?${new Date().getTime()}`}
                    alt=""
                    className="banner-picture"
                  />
                  {user?.userId === profile?.userId && (
                    <img
                      src={camera}
                      alt=""
                      onClick={() =>
                        document.getElementById("cover-photo-upload").click()
                      }
                      id="file-upload"
                      style={{
                        width: "28px",
                        position: "relative",
                        float: "right",
                        right: "15px",
                        top: "-40px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  <input
                    type="file"
                    id="cover-photo-upload"
                    style={{ display: "none" }}
                    onChange={handleBannerUpload}
                  />
                </div>
                <Avatar
                  src={`${profile?.profilePicture}?${new Date().getTime()}`}
                  className="profile-avatar"
                />
                {user?.userId === profile?.userId && (
                  <IconButton
                    className="edit-avatar-btn"
                    onClick={() =>
                      document.getElementById("profile-photo-upload").click()
                    }
                  >
                    <img src={camera} alt="" style={{ width: "100%" }} />
                  </IconButton>
                )}
                <input
                  type="file"
                  id="profile-photo-upload"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                  multiple={false}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "16px",
                    fontFamily: "Poppins, Regular",
                    fontWeight: "400",
                    position: "relative",
                    top: "55px",
                    left: "10px",
                  }}
                >
                  {profile?.fullName || profile?.businessName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Poppins, Regular",
                    fontWeight: "400",
                    position: "relative",
                    top: "55px",
                    left: "10px",
                  }}
                >
                  @{profile?.userName}
                </Typography>
                {user?.userId === profile?.userId && (
                  <Button
                    variant="outlined"
                    className="editprofile-btn"
                    onClick={handleEditProfileClick}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Poppins, Regular",
                      fontWeight: "500",
                      position: "relative",
                      top: "-20px",
                      float: "right",
                      right: "20px",
                    }}
                  >
                    <img
                      src={editprofile}
                      alt=""
                      style={{ width: "15%", margin: "5px" }}
                    />{" "}
                    Edit Profile
                  </Button>
                )}
                <div className="profile-details">
                  {profile?.location && (
                    <Link
                      variant="body2"
                      sx={{
                        display: "flex",
                        width: "120px",
                        height: "20px",
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        fontWeight: "400",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "#000",
                      }}
                    >
                      <img src={locations} alt="" style={{ width: "20%" }} />{" "}
                      {profile?.location}
                    </Link>
                  )}
                  {profile?.dateOfBirth && (
                    <Link
                      variant="body2"
                      sx={{
                        display: "flex",
                        width: "auto",
                        height: "20px",
                        fontSize: "14px",
                        fontFamily: "Poppins, Regular",
                        fontWeight: "400",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "#000",
                      }}
                    >
                      <img src={cacke} alt="" style={{ width: "12%" }} />{" "}
                      {profile.dateOfBirth}
                    </Link>
                  )}
                </div>
                <div className="following-details">
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      {profile.followingCount}
                    </span>{" "}
                    Following
                  </p>
                  <p>
                    <span style={{ fontWeight: "600" }}>
                      {profile.followersCount}
                    </span>{" "}
                    Followers
                  </p>
                </div>
              </div>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <form action="">
                  <Dialog
                    open={openEditModal}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                  >
                    <DialogTitle>Edit Personal Information</DialogTitle>
                    <DialogContent>
                      <TextField
                        fullWidth
                        margin="normal"
                        label="User Name"
                        onChange={(e) => {
                          setUserName(e.target.value);
                        }}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="email address"
                        onChange={(e) => {
                          setEmailAddress(e.target.value);
                        }}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        select
                        onChange={(e) => {
                          setGenderType(e.target.value);
                        }}
                        margin="normal"
                        label="Gender"
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                        }}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </TextField>
                      <DatePicker
                        label="Date of Birth"
                        onChange={(date) => {
                          console.log("OnChangew date", date);
                          setDateOfBirth(new Date(date));
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth margin="normal" {...params} />
                        )}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            width: "545px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Phone Number"
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                        }}
                      />
                      <TextField
                        fullWidth
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        margin="normal"
                        label="Location"
                        sx={{
                          mb: 2,
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "30px",
                            height: "47px",
                            border: "1px solid #d3d3d3",
                            padding: "1px 14px",
                          },
                        }}
                      />
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{
                          mt: 2,
                          borderRadius: "30px",
                          background:
                            "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                        }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save
                      </Button>
                    </DialogContent>
                  </Dialog>
                </form>
              </LocalizationProvider>
            </div>

            {/* Social Links */}
            <div className="social-links mt-3 p-3">
              <div className="social-title mb-3">
                <h3>Social Links</h3>

                {user?.userId === profile?.userId && (
                  <Button
                    variant="outlined"
                    className="add-btn"
                    onClick={handleAddSocialClick}
                  >
                    <img
                      src={add}
                      alt=""
                      style={{ width: "30%", margin: "5px" }}
                    />{" "}
                    Add
                  </Button>
                )}
              </div>
              {/* HEllo */}
            </div>

            {/* Posts Section */}
            {posts ? (
              posts.map((post) => {
                return (
                  <div className="posts-section">
                    <div className="post">
                      <div className="post-header mb-4">
                        <div className="post-author">
                          <div className="post-headers">
                            <Avatar src={post?.user?.profilePicture} />
                            <div className="post-author-info">
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontSize: "16px",
                                  fontFamily: "Poppins, Medium",
                                  fontWeight: "500",
                                }}
                              >
                                {post?.user?.fullName}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "14px",
                                  fontFamily: "Poppins, Regular",
                                  color: "#63676A",
                                }}
                              >
                                @{post?.user?.userName}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className="post-btn">
                          <IconButton
                            sx={{
                              border: "2px solid black",
                              width: "26px",
                              height: "26px",
                              color: "black",
                            }}
                          >
                            <MoreHoriz />
                          </IconButton>
                        </div>
                      </div>
                      <Typography
                        variant="body2"
                        className="post-content"
                        style={{
                          fontSize: "14px",
                          fontFamily: "Poppins, Regular",
                          fontWeight: "normal",
                        }}
                      >
                        {post?.content}
                        {!showMore && (
                          <>
                            <span> </span>
                            <Button
                              variant="text"
                              sx={{
                                fontSize: "12px",
                                padding: "0",
                                minWidth: "unset",
                                color: "#000",
                                fontWeight: "600",
                                fontFamily: "Poppins, regular",
                              }}
                              onClick={handleShowMore}
                            >
                              More.
                            </Button>
                          </>
                        )}
                      </Typography>

                      {post?.media?.map((media) => {
                        if (media?.type === "photos") {
                          return (
                            <Zoom>
                              <img
                                src={media.url}
                                alt="Vasa Ship"
                                className="post-image"
                              />
                            </Zoom>
                          );
                        }
                        if (media?.type === "video") {
                          return (
                            <video
                              controls
                              className="post-image"
                              controlsList="nodownload"
                            >
                              <source src={media.url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          );
                        }
                      })}
                      <div
                        className="post-interactions d-flex align-items-center"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          className="feed-icon d-flex align-item-center"
                          style={{ gap: "15px" }}
                        >
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                            onClick={(e) => manageLike(e, post)}
                          >
                            {post?.isLiked ? (
                              <img
                                src={likess}
                                alt="Like"
                                style={{ width: "20px", height: "20px" }}
                              />
                            ) : (
                              <img
                                src={likes}
                                alt="Like"
                                style={{ width: "20px", height: "20px" }}
                              />
                            )}
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              {post?.likeCount}
                            </span>
                          </IconButton>

                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                            onClick={() => toggleCommentBox(post?.postId)}
                          >
                            <img
                              src={comment}
                              alt="Comment"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              {post?.commentCount}
                            </span>
                          </IconButton>

                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={report}
                              alt="Report"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Poppins, Regular",
                                fontWeight: "300",
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            >
                              45
                            </span>
                          </IconButton>
                        </div>

                        <div className="bookmarkrs d-flex align-items-center">
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={upload}
                              alt="Upload"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                          </IconButton>
                          <IconButton
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={save}
                              alt="Save"
                              style={{
                                width: "20px",
                                height: "20px",
                                position: "relative",
                                top: "2px",
                              }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="create-posts-section">
                <div className="create-posts">
                  <img src={createposts} alt="Creating Poosts" />
                  <h2>hasn't posted yet</h2>
                  <Link
                    sx={{
                      fontSize: "15px",
                      fontFamily: "Poppins, regular",
                      fontWeight: "400",
                      textDecoration: "none",
                      color: "#9A00A9",
                      cursor: "pointer",
                    }}
                  >
                    Create a Post
                  </Link>
                </div>
              </div>
            )}
          </Grid>

          {commentModal && (
            <CommentsModal
              onClose={toggleCommentBox}
              open={commentModal}
              postId={commentModal}
              onComment={onComment}
            />
          )}

          {/* Right Sidebar (Suggested for You) */}
          <Grid item xs={3}>
            <RightSide />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
