import { AxiosError } from "axios";
import { toast } from "react-toastify";

export function errorHandler(error) {
  if (error instanceof AxiosError) {
    const message = error?.response?.data?.message;
    switch (error.status) {
      case 404:
        toast(message);
        break;
      case 400:
        toast(message);
        break;
      case 422:
        toast(message);
        break;
      case 409:
        toast(message);
        break;
      case 401:
        toast(message);
        break;
      case 500:
        toast("Internal Server Error");
        break;
      default:
        break;
    }
  }
}
