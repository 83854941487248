import React, { useState, useEffect, useRef, useCallback } from "react";
import { Avatar, Button, TextField, Modal, Box } from "@mui/material";
import gallery from "../img/gallery.png";
import emoji from "../img/emoji.png";
import podcost from "../img/podcost.png";
import poll from "../img/poll.png";
import choosephoto from "../img/choosephoto.png";
import choosevideo from "../img/choosevideo.png";
import Videos from "../img/videos.png";
import editimg from "../img/editimg.png";
import InputEmoji from "react-input-emoji";
import { networkRequest } from "../utils/network-request";
import { apiUrl } from "../config/api-urls";
import { toast } from "react-toastify";
import { contentEnum } from "../enum/content";
import { useUserProfile } from "../context/profile.contex";

export default function CommentsModal({ open, onClose, postId, onComment }) {
  const [comments, setComments] = useState([]);
  const [contentType, setContentType] = useState("");
  const [commentContent, setCommentContent] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastCreatedAt, setLastCreatedAt] = useState(false);

  const observerRef = useRef();

  const { user, isLoading, error, checkUserProfile } = useUserProfile();

  const checkButtonValidation = () => {
    if (commentContent) {
      return false;
    }
    return true;
  };

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const imageUrls = Array.from(files).map((file) => {
      return { file: file, imageUrl: URL.createObjectURL(file) };
    });
    setImages((prevImages) => [...prevImages, ...imageUrls]);
  };

  const handleVideoUpload = (event) => {
    const files = event.target.files;
    const videoUrls = Array.from(files).map((file) => {
      return { file: file, videoUrl: URL.createObjectURL(file) };
    });
    setVideos((prevVideos) => [...prevVideos, ...videoUrls]);
  };

  const handleRemoveVideo = (videoUrl) => {
    setVideos((prevVideos) =>
      prevVideos.filter((video) => video?.videoUrl !== videoUrl)
    );
  };

  const handleRemoveImage = (imageUrl) => {
    setImages((prevImages) =>
      prevImages.filter((image) => image.imageUrl !== imageUrl)
    );
  };

  async function onSubmit() {
    const result = await networkRequest("post", apiUrl.CREATE_COMMENT, {
      content: commentContent,
      postId: postId,
    });

    if (!result.data) {
      toast("Somthing went wrong while adding reply");
    }

    setComments((prev) => {
      prev.unshift(result.data);
      return structuredClone(prev);
    });

    onComment(postId);

    setCommentContent("");
  }

  async function getComments() {
    const result = await networkRequest(
      "get",
      apiUrl.GET_COMMENTS,
      {},
      {},
      { postId: postId }
    );
    setComments(result.data.comments);
    if (result.data.comments < 10) {
      setLastCreatedAt(null);
    } else {
      const createdAtW = result.data.createdAt;
      setLastCreatedAt(createdAtW);
    }
  }

  async function getCommentsNext() {
    const result = await networkRequest(
      "get",
      apiUrl.GET_COMMENTS,
      {},
      {},
      { postId: postId, ...(lastCreatedAt && { createdAt: lastCreatedAt }) }
    );
    setComments((prev) => {
      prev.push(...result.data.comments);
      return structuredClone(prev);
    });
    if (result.data.comments.length < 10) {
      setLastCreatedAt(null);
    } else {
      setLastCreatedAt(result.data.createdAt);
    }
  }

  const lastItemRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (lastCreatedAt) {
            getCommentsNext();
          }
        }
      });

      if (node) observerRef.current.observe(node);
    },
    [loading, lastCreatedAt]
  );

  useEffect(() => {
    if (postId) {
      getComments();
    }
  }, [postId]);

  if (!open) {
    return null;
  } else {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "80%",
            width: { xs: "90%", sm: "70%", md: "50%" },
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "16px",
            p: 3,
            backgroundColor: "#fff",
          }}
        >
          {/* Header - Commented User Info */}
          <div className="comment-section" style={{ height: "80%" }}>
            <h3 style={{ marginBottom: "1rem", fontWeight: "bold" }}>
              Comments
            </h3>
            <div style={{ overflowY: "scroll", height: "100%" }}>
              {comments.map((comment, index) => {
                return (
                  <div
                    ref={index === comments.length - 1 ? lastItemRef : null}
                    className="comment-header"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <Avatar
                      src={comment?.user?.profilePicture}
                      alt="Science & Facts"
                      style={{ marginRight: "1rem" }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <h4 style={{ margin: 0, fontWeight: "bold" }}>
                        {comment?.user?.fullName || comment.user.businessName}
                      </h4>
                      <h5 style={{ margin: 0, color: "#999" }}>
                        @{comment?.user?.userName}
                      </h5>
                      <p style={{ marginTop: "0.5rem" }}>{comment.content}</p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <Avatar
                src={user?.profilePicture}
                alt="John Tomas"
                style={{ marginRight: "1rem" }}
              />
              <InputEmoji
                value={commentContent}
                onChange={(text) => setCommentContent(text)}
                placeholder="Write your reply"
                inputProps={{
                  maxLength: 500,
                  style: {
                    padding: "10px",
                    borderRadius: "10px",
                    border: "1px solid #ddd",
                    width: "100%",
                    backgroundColor: "#f8f9fa",
                  },
                }}
              />
              <div style={{ textAlign: "right" }}>
                <span>{commentContent.length}/500</span>
              </div>
            </div>

            {contentType === contentEnum.IMAGE && (
              <div
                className="gallery-upload-section"
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    border: "2px dashed #ccc",
                    borderRadius: "10px",
                    padding: images.length > 0 ? "0" : "20px",
                    textAlign: "center",
                    backgroundColor: "#f8f9fa",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                    flexDirection: "column",
                  }}
                >
                  {images.length === 0 && (
                    <>
                      <p>Add Photo drag or drop here...</p>
                    </>
                  )}

                  <TextField
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    sx={{ display: "none" }}
                    id="file-upload"
                  />
                  <label
                    htmlFor="file-upload"
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={choosephoto} alt="" style={{ width: "40%" }} />
                  </label>

                  <div
                    className="image-preview-container"
                    style={{
                      marginTop: "16px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    {images.map((imageUrl, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={imageUrl}
                          alt={`Selected ${index + 1}`}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "10px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          }}
                          // onClick={() => handleFullscreenOpen(imageUrl)}
                        />
                        <Button
                          // onClick={() => handleEditImage(imageUrl)}
                          sx={{
                            position: "absolute",
                            top: "0px",
                            left: "-38px",
                            minWidth: "24px",
                            minHeight: "24px",
                            borderRadius: "50%",
                            padding: "0",
                            background: "none",
                          }}
                        >
                          <img src={editimg} alt="" style={{ width: "25%" }} />
                        </Button>
                        <Button
                          onClick={() => handleRemoveImage(imageUrl)}
                          sx={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            minWidth: "24px",
                            minHeight: "24px",
                            borderRadius: "50%",
                            padding: "0",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.7)",
                            },
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {contentType === contentEnum.VIDEO && (
              <div
                className="video-upload-section"
                style={{ position: "relative" }}
              >
                <div
                  style={{
                    border: "2px dashed #ccc",
                    borderRadius: "10px",
                    padding: videos.length ? "0" : "20px",
                    textAlign: "center",
                    backgroundColor: videos.length ? "transparent" : "#f8f9fa",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                    flexDirection: "column",
                  }}
                >
                  {!videos.length && (
                    <>
                      <p>Add Video drag or drop here...</p>
                    </>
                  )}
                  <TextField
                    type="file"
                    accept="video/*"
                    onChange={handleVideoUpload}
                    sx={{ display: "none" }}
                    id="video-upload"
                    multiple
                  />
                  <label
                    htmlFor="video-upload"
                    style={{
                      position: "relative",
                      top: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={choosevideo} alt="" style={{ width: "40%" }} />
                  </label>
                  <div
                    className="video-preview-container"
                    style={{
                      marginTop: "16px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      width: "100%",
                      height: "auto",
                    }}
                  >
                    {videos.map((videoUrl, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          display: "inline-block",
                          width: "100%",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <video
                          src={videoUrl}
                          controls
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "10px",
                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            cursor: "pointer",
                          }}
                          // onClick={() => handleFullscreenOpen(videoUrl)}
                        />
                        <Button
                          // onClick={() => handleEditVideo(videoUrl)}
                          sx={{
                            position: "absolute",
                            top: "0px",
                            left: "-38px",
                            minWidth: "24px",
                            minHeight: "24px",
                            borderRadius: "50%",
                            padding: "0",
                            background: "none",
                          }}
                        >
                          <img src={editimg} alt="" style={{ width: "25%" }} />
                        </Button>
                        <Button
                          onClick={() => handleRemoveVideo(videoUrl)}
                          sx={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            minWidth: "24px",
                            minHeight: "24px",
                            borderRadius: "50%",
                            padding: "0",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.7)",
                            },
                          }}
                        >
                          &times;
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* Icons and Reply Button */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              {/* Action Icons */}
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  height: "24px",
                  mr: 2,
                }}
              >
                <img
                  src={gallery}
                  alt="Add Photo"
                  style={{ cursor: "pointer" }}
                  onClick={() => setContentType(contentEnum.IMAGE)}
                />
                <img
                  src={Videos}
                  alt="Add Video"
                  style={{ cursor: "pointer" }}
                  onClick={() => setContentType(contentEnum.VIDEO)}
                />
                <img
                  src={podcost}
                  alt="Add Podcast"
                  style={{ cursor: "pointer" }}
                  onClick={() => setContentType(contentEnum.PODCAST)}
                />
                <img
                  src={emoji}
                  alt="Add Emoji"
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={poll}
                  alt="Add Document"
                  style={{ cursor: "pointer" }}
                />
              </div>

              {/* Reply Button */}
              <Button
                variant="contained"
                disabled={checkButtonValidation()}
                onClick={onSubmit}
                sx={{
                  background:
                    "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                  color: checkButtonValidation() ? "#999" : "#fff",
                  borderRadius: "30px",
                  padding: "0.5rem 2rem",
                  textTransform: "none",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                  },
                }}
              >
                Reply
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    );
  }
}
