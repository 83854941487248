const baseUrl = process.env.BASE_URL || "https://api.user.activatree.com";
const postUrl = "https://api.post.activatree.com";

export const apiUrl = {
  SIGN_UP_OTP: `${baseUrl}/api/user/otp/sign-up`,
  SIGN_UP: `${baseUrl}/api/user/sign-up`,
  LOGIN: `${baseUrl}/api/user/login`,
  GET_SUGGESTED_USERS: `${baseUrl}/api/user/suggested`,
  ADD_PROFILE_PICTURE: `${baseUrl}/api/user/profile-picture`,
  OTP_VERIFY: `${baseUrl}/api/user/otp/verify`,
  USER_PROFILE: `${baseUrl}/api/user`,
  USER_FOLLOW: `${baseUrl}/api/user/follow`,
  USER_UN_FOLLOW: `${baseUrl}/api/user/follow`,
  USER_BANNER_PICTURE: `${baseUrl}/api/user/banner-picture`,
  GET_POSTS: `${postUrl}/api/post`,
  CREATE_POST: `${postUrl}/api/post`,
  CREATE_LIKE: `${postUrl}/api/like`,
  DELETE_LIKE: `${postUrl}/api/like`,
  GET_COMMENTS: `${postUrl}/api/comment`,
  CREATE_COMMENT: `${postUrl}/api/comment`,
  DELETE_COMMENT: `${postUrl}/api/comment`,
};
