import React, { useRef } from "react";
import { Button } from "@mui/material";
import NavBar from "./navbar";
import "../css/homepage.css";
import "../css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import girl1 from "../img/girl1.png";
import upercommnet3 from "../img/upercomment3.png";
import like from "../img/like.png";
import people from "../img/people.png";
import uparrow from "../img/uparrow.png";
import downcomment3 from "../img/downcomment3.png";
import line from "../img/line.png";
import line1 from "../img/line1.png";
import dot from "../img/dot.png";
import solution1 from "../img/solution1.png";
import solution2 from "../img/solution2.png";
import globalnetwork from "../img/globalnetwork.png";
import mns from "../img/mns.png";
import collobraterealtime from "../img/collobraterealtime.png";
import pnp from "../img/pnp.png";
import lnr from "../img/lnr.png";
import ewr from "../img/ewr.png";
import fnc from "../img/fnc.png";
import ass from "../img/ass.png";
import rightarrow from "../img/rightarrow.png";
import collobrationrealtime from "../img/collobrationrealtime.png";
import momentize from "../img/momentize.png";
import grow from "../img/grow.png";
import aipowered from "../img/aipowered.png";
import singup from "../img/singup.png";
import secondstage from "../img/secondstage.png";
import connectglobally from "../img/connectglobally.png";
import thirdstage from "../img/thirdstage.png";
import earnwithoutrestriction from "../img/earnwithoutrestriction.png";
// import Slider from "react-slick";
// import doublecouots from "../img/doublecouots.png";
// import saramitchell from "../img/sarahmitchell.png";
// import dominicdavis from "../img/dominicdavis.png";
// import emmawilliams from "../img/emmawilliams.png";
// import jamesturner from "../img/jamesturner.png";
// import prevslide from "../img/prevslide.png";
// import nextslide from "../img/nextslide.png";
// import linarodriguez from "../img/linarodriguez.png";
// import jamesturner2 from "../img/jamesturner2.png";
// import alexpranto from "../img/alexpranto.png";
// import carlosdiaz from "../img/carlosdiaz.png";
import { useNavigate } from "react-router-dom";
import forword from "../img/forword.png";
import Footer from "./footer";

export default function HomePage() {
  const navigate = useNavigate();
  // const sliderRef = useRef(null);

  // const nextSlide = () => sliderRef.current.slickNext();
  // const prevSlide = () => sliderRef.current.slickPrev();

  // var settings = {
  //     dots: true,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     autoplay: true,
  //     autoplaySpeed: 3000,
  //     responsive: [
  //         {
  //             breakpoint: 1024,
  //             settings: {
  //                 slidesToShow: 3,
  //             },
  //         },
  //         {
  //             breakpoint: 768,
  //             settings: {
  //                 slidesToShow: 2,
  //             },
  //         },
  //         {
  //             breakpoint: 480,
  //             settings: {
  //                 slidesToShow: 1,
  //             },
  //         },
  //     ],
  // };

  const handleSignUpClick = () => {
    navigate("/signup");
  };

  const handleLoginClick = () => {
    navigate("/loginform");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <NavBar />
          <div className="row py-5">
            <div className="col-lg-6 col-md-6">
              <div className="header">
                <ul className="title-header p-0">
                  <img className="dot" src={dot} alt="" />
                  <li className="p-2">
                    A Digital Ecosystem For Young Entrepreneurs To
                  </li>
                </ul>
                <h2>
                  Network. Collaborate. Acquire Skills. Innovate. Showcase
                  Ideas. Build and Monetize Your Brand.
                </h2>
                <p>Turn Your CRAZY IDEAS Into REALITY</p>
                <div className="details mt-5">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLoginClick}
                    className="hover-expand-btn"
                    sx={{
                      mr: 2,
                      borderRadius: "30px",
                      padding: "0px",
                      width: "88px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      background:
                        "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
                      textTransform: "none",
                    }}
                  >
                    Login
                    <span className="arrow-icon">
                      <img
                        src={forword}
                        alt="Forword"
                        style={{ width: "80%" }}
                      />
                    </span>
                  </Button>

                  <Button
                    onClick={handleSignUpClick}
                    variant="contained"
                    color="white"
                    sx={{
                      p: 0,
                      borderRadius: "30px",
                      border: "1px solid black",
                      width: "88px",
                      height: "47px",
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#000",
                      textTransform: "none",
                    }}
                  >
                    Sing Up
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="home-img">
                <img className="girl" src={girl1} alt="" />
                <img className="upercomment3" src={upercommnet3} alt="" />
                <img className="like" src={like} alt="" />
                <img className="people" src={people} alt="" />
                <img className="uparrow" src={uparrow} alt="" />
                <img className="downcomment3" src={downcomment3} alt="" />
              </div>
            </div>
          </div>
        </div>
        <img className="line" src={line} alt="" />
        <img className="line1" src={line1} alt="" />
      </div>

      <div
        className="container mb-5"
        style={{ position: "relative", top: "-20pc" }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="solution1">
              <img
                src={solution1}
                alt="Social Media Icons"
                className="image-left"
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="text-container">
              <h1>Why Choose</h1>
              <h2>Activatree</h2>
              <p>Save Time On Content Creation</p>
              <p>
                Easily integrate and manage your social media accounts from one
                Dashboard. Grow your audience globally, and build your brand.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-4">
            <div className="solution2">
              <img
                src={solution2}
                alt="Person with Tablet"
                className="image-right"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container platforms">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6">
            <div className="platform">
              <h1>
                Our Platform{" "}
                <span
                  style={{
                    color: "#9F00AA",
                    fontFamily: "poppins",
                    fontWeight: 200,
                  }}
                >
                  Features
                </span>
              </h1>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="platform">
              <p>
                Explore a suite of powerful tools designed to enhance
                collaboration, creativity and professional growth. From
                real-time co-creation to AI-powered networking, Activatree
                offers everythinng you need to succeed
              </p>
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Global Network</h2>
              <p>
                Expand your network. Connect globally. Build professional
                relationships early. Build high-value teams.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={globalnetwork} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>Mentorship & Support</h2>
              <p>
                Connect with experienced mentors. Access to successful teen
                entrepreneurs. And solid community support system.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={mns} alt="" />
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Collaborate in Real-Time</h2>
              <p>
                Whether it's co-creating posts, sharing a project, or
                contributing to discussions. We make interaction simple and
                effective, to build the ideas that matter to you.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={collobraterealtime} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>Professional Profiles</h2>
              <p>
                Set up Individual or Business pages to represent who you are and
                showcase your business
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={pnp} alt="" />
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Learning and Resources</h2>
              <p>
                Acquire in-demand business development skills. Entrepreneurship,
                marketing, and sales training.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={lnr} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>Earn Without Restriction</h2>
              <p>
                Interactive marketplace to buy and sell digital products.
                Monetize your expertise. Create subscription models to turn your
                content into revenue.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={ewr} alt="" />
            </div>
          </div>
        </div>

        <div className="global-network row">
          <div className="col-lg-6">
            <div className="gloabal-network">
              <h2>Forums and Community</h2>
              <p>
                Join discussions that support your goals. Share Knowledge. Solve
                Industry relevant problems.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="global-network-img">
              <img src={fnc} alt="" />
            </div>
          </div>
        </div>

        <div className="mns row">
          <div className="col-lg-6">
            <div className="mns-text">
              <h2>A Safe Space</h2>
              <p>
                Protected Environment. Age-appropriate content. Safe networking.
                Privacy protection. With 24/7 user support.
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mns-img">
              <img src={ass} alt="" />
            </div>
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-3">
                        <div className="creation">
                            <img src={creatingline} alt="Creattingline" className='creationline' />
                            <img src={creating} alt="Creating" className="creating" />
                            <div className="text-details">
                                <h3>Collaborative Content Creation</h3>
                                <p style={{ marginTop: '32px' }}>Work with multipal creators in real-time on a single post. Expand your content's potential through collaboration and innovation.</p>
                                <Link href="/socials" sx={{ display: 'flex', alignItems: 'center', marginTop: '25px', fontSize: 14, fontFamily: 'poppins', fontWeight: 600, color: '#000', textDecoration: 'none' }}>
                                    Start Co-Creaing <img src={rightarrow} alt="" style={{ width: '7%', margin: '5px' }} />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3">
                        <div className="creation">
                            <img src={buildprofileline} alt="Creattingline" className='buildprofileline1' />
                            <img src={buildprofile} alt="Creating" className="creating" />
                        </div>
                        <div className="text-details">
                            <h3>Interactive Profiles & Networking</h3>
                            <p>Build a dynamic and engaging profile that connects you with professionals, buisnesses, and influencers across the globe.</p>
                            <Link href="/socials" sx={{ display: 'flex', alignItems: 'center', marginTop: '25px', fontSize: 14, fontFamily: 'poppins', fontWeight: 600, color: '#000', textDecoration: 'none' }}>
                                Build Your Profile <img src={rightarrow} alt="" style={{ width: '7%', margin: '5px' }} />
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3">
                        <div className="creation">
                            <img src={marketplaceline} alt="Creattingline" className='buildprofileline' />
                            <img src={discovermarketplace} alt="Creating" className="creating" />
                        </div>
                        <div className="text-details">
                            <h3>Marketplace for Digital Goods</h3>
                            <p style={{ marginTop: '32px' }}>Discover a marketplace where you can buy, sell and trade digital goods. Whether your'e offering digital services or products, our marketplace is your platform for success.</p>
                            <Link href="/socials" sx={{ display: 'flex', alignItems: 'center', marginTop: '4px', fontSize: 14, fontFamily: 'poppins', fontWeight: 600, color: '#000', textDecoration: 'none' }}>
                                Discover the Marketplace <img src={rightarrow} alt="" style={{ width: '7%', margin: '5px' }} />
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3">
                        <div className="creation">
                            <img src={earningline} alt="Creattingline" className='buildprofileline' />
                            <img src={startearning} alt="Creating" className="creating" />
                        </div>
                        <div className="text-details">
                            <h3>Monetization Opportunites</h3>
                            <p style={{ marginTop: '32px' }}>Unlock the potential to monetize your expertise. Earn through premiumn conetnt, digital products or services tailored to your audience.</p>
                            <Link href="/socials" sx={{ display: 'flex', alignItems: 'center', marginTop: '25px', fontSize: 14, fontFamily: 'poppins', fontWeight: 600, color: '#000', textDecoration: 'none' }}>
                                Start Earning <img src={rightarrow} alt="" style={{ width: '7%', margin: '5px' }} />
                            </Link>
                        </div>
                    </div> */}
      </div>

      {/* <div className="container mt-5 py-4">
                <header className="selection">
                    <h1>Why Choose <span className="activatree">ActivaTree</span></h1>
                    <p>Activatree empowers creators and professionals to collaborate, monetize, and grow their networks seamlessly. With cutting-edge tools and AI-driven personalization, it's the platform where creativity meets opportunity.</p>
                </header>
                <div className="features">
                    <div className="row">
                        <div className="feature">
                            <div className="col-lg-1 mb-3">
                                <img src={collobrationrealtime} alt="Collaborate in Real-Time" />
                            </div>
                            <div className="col-lg-6 mb-3">
                                <h2>Collaborate in Real-Time</h2>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <p>Bring your ideas to life by working with creators from anywhere in the world in real-time.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="feature">
                            <div className="col-lg-1 mb-3">
                                <img src={momentize} alt="Monetize Your Expertise" />
                            </div>
                            <div className="col-lg-6 mb-3">
                                <h2>Monetize Your Expertise</h2>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <p>Earn money by offering digital products or services, and make the most out of your skills.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="feature">
                            <div className="col-lg-1 mb-3">
                                <img src={grow} alt="Grow Your Professional Network" />
                            </div>
                            <div className="col-lg-6 mb-3">
                                <h2>Grow Your Professional Network</h2>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <p>Build relationships with global professionals, influencers, and businesses using interactive profile features.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="feature">
                            <div className="col-lg-1 mb-3">
                                <img src={aipowered} alt="AI-Powered Personalization" />
                            </div>
                            <div className="col-lg-6 mb-3">
                                <h2>AI-Powered Personalization</h2>
                            </div>
                            <div className="col-lg-4 mb-3">
                                <p>Get personalized content recommendations and grow faster with our AI-driven tools.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="container working-steps mt-5 py-5">
        <header className="working">
          <h1>
            How It <span className="works">Works</span>
          </h1>
          <p>
            Sign up for Activatree. Define your goals. Build your profile.
            Connect and network with professionals. Collaborate on Ideas and
            projects. Build your brand, business, and team. Monetize your
            expertise or content.
          </p>
          <p>
            <span
              style={{ fontWeight: "600", fontSize: "20px", color: "#000" }}
            >
              Join a thriving community and get creative.
            </span>
          </p>
        </header>
        <div className="steps">
          <div className="row" style={{ gap: 23, alignItems: "center" }}>
            <div className="col-lg-3">
              <div className="step">
                <img
                  src={singup}
                  alt="Sign Up"
                  style={{ marginBottom: "70px" }}
                />
                <h2>Claim Free Trial</h2>
                <p>Create your account. Build and customize your profile.</p>
              </div>
            </div>
            <img src={secondstage} alt="Stapes" className="nextstep" />
            <div className="col-lg-3">
              <div className="step">
                <img src={connectglobally} alt="Build Up Your Profile" />
                <h2>Connect Globally</h2>
                <p>
                  Network with other creators and professionals. Develop Ideas
                  and collaborate.
                </p>
              </div>
            </div>
            <img src={thirdstage} alt="Stapes" className="nextstep" />
            <div className="col-lg-3">
              <div className="step">
                <img
                  src={earnwithoutrestriction}
                  alt="Start Creating or Selling"
                />
                <h2>Earn without restriction</h2>
                <p>
                  Monetize your Content. Sell your digital products and
                  services.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSignUpClick}
          className="hover-expand-btn"
          sx={{
            mr: 2,
            borderRadius: "30px",
            padding: "0px",
            ml: 2,
            width: "165px",
            height: "47px",
            fontFamily: "poppins",
            fontWeight: "500",
            fontSize: "16px",
            background:
              "linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)",
            textTransform: "none",
          }}
        >
          Sign up for Now
          <span className="arrow-icon">
            <img src={forword} alt="Forword" style={{ width: "80%" }} />
          </span>
        </Button>
      </div>

      {/* <div className="container mb-5">
                <div className="testimonials-section">
                    <h2>Client Testimonials <br /><span style={{ color: '#9F00AA', fontWeight: '100' }}>About Activatree</span></h2>
                    <p>Our users love Activatree for its seamless collaboration and powerful networking tools. Hear from creators, professionals, and entrepreneurs who have transformed their projects and careers with our platform.</p>
                    <div className="slider mt-5">
                        <Slider ref={sliderRef} {...settings}>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={saramitchell} alt="Sara Mitchell" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"Activatree has completely changed the way I manage my social media campaigns. The collaborative tools and seamless scheduling features have saved me countless hours. It’s a game-changer for anyone looking to streamline their digital presence."</h4>
                                <h3 className='person-name ms-0 mt-5'>Sara Mitchell</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '-5px' }}>Digital Marketing Specialist</h4>
                            </div>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={dominicdavis} alt="Dominic Davis" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"With Activatree, I can co-create content with other influencers in real-time, which has opened up so many new opportunities for my brand. The platform's focus on collaboration has helped me grow my audience and create more engaging content."</h4>
                                <h3 className='person-name ms-0 mt-5'>Dominic Davis</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '-5px' }}>Content Creator</h4>
                            </div>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={emmawilliams} alt="Emma Williams" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"As a small business owner, Activatree's marketplace has allowed me to connect with talented creators and digital service providers that I never would have found otherwise. The platform makes it easy to collaborate, and the results have been fantastic."</h4>
                                <h3 className='person-name ms-0 mt-5'>Emma Williams</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '-5px' }}>Entrepreneur</h4>
                            </div>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={jamesturner} alt="James Turner" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"I've tried many platforms, but none have offered the level of professional networking and content creation opportunities that Activatree provides. The AI-powered personalization tools have helped me connect with clients who value my work and are willing to pay for quality."</h4>
                                <h3 className='person-name ms-0 mt-5'>James Turner</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '-5px' }}>Freelance Graphic Designer</h4>
                            </div>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={linarodriguez} alt="Lina Rodriguez" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"I love how easy it is to collaborate with other creations on Activatree. The co-create posts feature has allowed me to create exciting content with other influencers, and the monetization tools make it simple to trun my creativity into income."</h4>
                                <h3 className='person-name ms-0 mt-5'>Lina Rodriguez</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '-5px' }}>Social Media Influencer</h4>
                            </div>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={carlosdiaz} alt="Carlos Diaz" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"Activatree's platform has been essential in helping me grow my network. The interactive profiles and real-time collaboration tools make it a great space to connect with other professionals and work on exciting new projects."</h4>
                                <h3 className='person-name ms-0' style={{ marginTop: '65px' }}>Carlos Diaz</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '0px' }}>Videographer</h4>
                            </div>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={alexpranto} alt="Alex Pranto" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"Activatree has transfromed how I create and collaborate with others. The Platform is intutive, powerful and tailored for creators."</h4>
                                <h3 className='person-name ms-0' style={{ marginTop: '100px' }}>Alex Pranto</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '0px' }}>Software Engineer</h4>
                            </div>
                            <div className='sarahmitchell'>
                                <div className="profile">
                                    <img src={jamesturner2} alt="James Turner" />
                                </div>
                                <div className="doublecouots">
                                    <img src={doublecouots} alt="Doublecouots" />
                                </div>
                                <h4 className='testimonial-text'>"I've tried many platforms, but none have offered the level of professional networking and content creation opportunities that Activatree provides. The AI-powered personalization tools have helped me connect with clients who value my work and are willing to pay for quality."</h4>
                                <h3 className='person-name ms-0 mt-5'>James Turner</h3>
                                <h4 className='testimonial-text' style={{ marginLeft: '-5px' }}>Freelance Graphic Designer</h4>
                            </div>
                        </Slider>
                    </div>
                    <div className="slider-navigation bg-white">
                        <button onClick={prevSlide} className="prev-arrow bg-white"><img src={prevslide} alt="Prev Slide" /></button>
                        <button onClick={nextSlide} className="next-arrow bg-white"><img src={nextslide} alt="Next Slide" /></button>
                    </div>
                </div>
            </div> */}
      <Footer />
    </>
  );
}
