import React, { useState } from 'react';
import { Avatar, Button, IconButton, Typography, Link, Grid, InputAdornment, TextField } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import '../css/profilepage.css';
import NavBar from './navbar';
import Container from 'react-bootstrap/Container';
import johntomas from '../img/jhontomas.png';
import company from '../img/company.png';
import createpost from '../img/createpost.png';
import settings from '../img/settings.png';
import saved from '../img/saved.png';
import profiles from '../img/profiles.png';
import page from '../img/page.png';
import monetize from '../img/monetize.png';
import live from '../img/live.png';
import group from '../img/group.png';
import followingfollowers from '../img/followingfollowers.png';
import freindrequest from '../img/freindrequest.png';
import reels from '../img/reels.png';
import video from '../img/video.png';
import SearchIcon from '@mui/icons-material/Search';
import ads from '../img/ads.png';
import willow from '../img/willow.png';
import sam from '../img/sam.png';
import roy from '../img/roy.png';
import neol from '../img/neol.png';
import logan from '../img/logan.png';
import lillian from '../img/lillian.png';
import kiran from '../img/kiran.png';
import kelvin from '../img/kelvin.png';
import karis from '../img/karis.png';
import jutus from '../img/jutus.png';
import juile from '../img/juile.png';
import isari from '../img/isari.png';
import hana from '../img/hana.png';
import emerson from '../img/emerson.png';
import billy from '../img/billy.png';
import camera from '../img/camera.png';
import location from '../img/location.png';
import cacke from '../img/cacke.png';
import calander from '../img/calander.png';
import editprofile from '../img/editprofile.png';
import add from '../img/add.png';
import facebook from '../img/facebook.png';
import google1 from '../img/google1.png';
import linkdin from '../img/linkdin.png';
import edit from '../img/edit.png';
import cream from '../img/cream.png';
import nailpolish from '../img/nailpolish.png';
import likes from '../img/likes.png';
import comment from '../img/comment.png';
import report from '../img/report.png';
import upload from '../img/upload.png';
import save from '../img/save.png';
import flowers from '../img/flowers.png';
import createposts from '../img/createposts.png';
import arrow from '../img/arrow.png';

export default function BusinessProfilePage() {

  const [showMore, setShowMore] = useState(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <NavBar />
      <Container>
        <Grid container spacing={2}>
          {/* Left Sidebar */}
          <Grid item xs={3}>
            <div className="sidebar">
              <div className="user-info d-flex align-items-center">
                <Avatar src={johntomas} alt="John Tomas" sx={{ width: 56, height: 56, mr: 2 }} />
                <div className="usertitle">
                  <Typography variant="h6" sx={{ fontSize: 14, fontFamily: 'poppins', fontWeight: 400, color: '#000' }}>John Tomas</Typography>
                  <Typography variant="body2" sx={{ fontSize: 12, fontFamily: 'poppins', fontWeight: 200, color: '#000' }}>@johnthomas</Typography>
                </div>
              </div>
              <Link to="/create-post" className="create-post-link" > {/* Specify the path to navigate */}
                <div className="create-post">
                  <div className="creating-post">
                    <img src={createpost} alt="Create Post" style={{ width: '100%' }} />
                  </div>
                  <div className="create-text">
                    <h4>Create a Post</h4>
                  </div>
                </div>
              </Link>
              <div className="sidebar-links">
                <div className="sidebar-link-title">
                  <h4>Explore</h4>
                </div>
                {[
                  { img: reels, text: 'Reels' },
                  { img: video, text: 'Video' },
                  { img: freindrequest, text: 'Friends Request' },
                  { img: followingfollowers, text: 'Following & Followers' },
                  { img: saved, text: 'Saved' },
                  { img: group, text: 'Group' },
                  { img: page, text: 'Page' },
                  { img: live, text: 'Live Streams' },
                  { img: monetize, text: 'Monetize' },
                  { img: profiles, text: 'Profile' },
                  { img: settings, text: 'Settings' },
                ].map((link, index) => (
                  <Button key={index} className="sidebar-link-button" sx={{ textTransform: 'none' }}>
                    <img src={link.img} alt={link.text} className="sidebar-link-icon" />
                    {link.text}
                  </Button>
                ))}
              </div>
            </div>
          </Grid>

          {/* Main Profile Section */}
          <Grid item xs={6}>

            <div className="back-page mb-2">
              <div className="back-page-title">
                <Button sx={{ width: '22px', height: '22px'}}><img src={arrow} alt="Arrow" style={{ width: '100%'}}/></Button>
                <div className="page-title">
                  <h2>Glow Beauty</h2>
                  <p>2 Posts</p>
                </div>
              </div>
            </div>

            <div className="profile-section">
              {/* Header and Profile Info */}
              <div className="profile-header">
                <div className="cover-photo" />
                <img src={camera} alt="" style={{ width: '28px', position: 'relative', float: 'right', right: '15px', top: '-40px' }} />
                <Avatar src={company} className="profile-avatar" />
                <IconButton className="edit-avatar-btn">
                  <img src={camera} alt="" style={{ width: '100%' }} />
                </IconButton>
                <Typography variant="h5" sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '400', position: 'relative', top: '55px', left: '10px' }}>Glow Beauty</Typography>
                <Typography variant="body2" sx={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '400', position: 'relative', top: '55px', left: '10px' }}>@glowbeauty</Typography>
                <Button variant="outlined" className="editprofile-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500', position: 'relative', top: '-20px', float: 'right', right: '20px' }} ><img src={editprofile} alt="" style={{ width: '15%', margin: '5px' }} /> Edit Profile</Button>
                <div className="profile-details">
                  <Link variant="body2" sx={{ display: 'flex', width: '120px', height: '20px', fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '400', alignItems: 'center', justifyContent: 'flex-start', cursor: 'pointer', textDecoration: 'none', color: '#000' }}><img src={location} alt="" style={{ width: '20%' }} /> New York</Link>
                  <Link variant="body2" sx={{ display: 'flex', width: 'auto', height: '20px', fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '400', alignItems: 'center', justifyContent: 'flex-start', cursor: 'pointer', textDecoration: 'none', color: '#000' }}><img src={calander} alt="" style={{ width: '12%' }} /> Joined January 2024</Link>
                </div>
                <div className="following-details">
                  <p><span style={{ fontWeight: '600' }}>200</span> Following</p>
                  <p><span style={{ fontWeight: '600' }}>1,043</span> Followers</p>
                </div>
              </div>
            </div>

            {/* Social Links */}
            <div className="social-links mt-3 p-3">
              <div className="social-title mb-2">
                <h3>Social Links</h3>
                <Button variant="outlined" className="add-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500', position: 'relative', top: '-5px' }} ><img src={add} alt="" style={{ width: '30%', margin: '5px' }} /> Add</Button>
              </div>
              <div className="row link-type">
                <div className="col-lg-5">
                  <div className="link">
                    <Link sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: "#000", fontSize: '13px', fontFamily: 'Poppins, Regular', fontWeight: '500', gap: '10px' }}>
                      <img src={facebook} alt='' className='icons' />
                      <p style={{ textDecoration: 'none' }}>https://www.facebook.com/</p>
                      <img src={edit} alt='' className='icons' /></Link>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="link">
                    <Link sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: "#000", fontSize: '13px', fontFamily: 'Poppins, Regular', fontWeight: '500', gap: '10px' }}>
                      <img src={google1} alt='' className='icons' />
                      <p>https://www.google.co.in/</p>
                      <img src={edit} alt='' className='icons' /></Link>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="link">
                    <Link sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: "#000", fontSize: '13px', fontFamily: 'Poppins, Regular', fontWeight: '500', gap: '10px' }}>
                      <img src={linkdin} alt='' className='icons' />
                      <p>https://in.linkedin.com/</p>
                      <img src={edit} alt='' className='icons' /></Link>
                  </div>
                </div>
              </div>

            </div>

            {/* Posts Section */}
            <div className="posts-section">
              <div className="post">
                <div className="post-header mb-4">
                  <div className="post-author">
                    <Avatar src={company} />
                    <div className="post-author-info">
                      <Typography variant="subtitle1" sx={{ fontSize: '16px', fontFamily: 'Poppins, Medium', fontWeight: '500' }}>Glow Beauty</Typography>
                      <Typography variant="body2" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', color: '#63676A' }}>@glowbeauty</Typography>
                    </div>
                  </div>
                  <IconButton sx={{ border: '2px solid black', width: '26px', height: '26px', color: 'black' }}><MoreHoriz /></IconButton>
                </div>
                <Typography variant="body2" className="post-content" style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: 'normal' }}>
                  Experience the luxurious tranquilty of our beauty product, inspired by serene oases enveloped in lush greenery and tranquily waters. Unlash your matural beauty with our cosmetic company's exiquisite offering.{!showMore && (
                    <>
                      <span> </span>
                      <Button variant="text" sx={{ fontSize: '12px', padding: '0', minWidth: 'unset', color: '#000', fontWeight: '600', fontFamily: 'Poppins, regular' }} onClick={handleShowMore}>
                        More.
                      </Button>
                    </>
                  )}
                </Typography>
                <div className="row mt-3">
                  <div className="col-lg-6 p-0">
                    <img src={cream} alt="Cream" className="post-image1" />
                  </div>
                  <div className="col-lg-6 p-0">
                    <img src={nailpolish} alt="Nailpolish" className="post-image2" />
                  </div>
                </div>
                <div className="post-interactions d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                  <div className="feed-icon d-flex align-item-center" style={{ gap: '15px' }}>
                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={likes} alt="Like" style={{ width: '20px', height: '20px' }} />
                      <span style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '300', position: 'relative', top: '3px', left: '10px' }}>15K</span>
                    </IconButton>

                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={comment} alt="Comment" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                      <span style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '300', position: 'relative', top: '3px', left: '10px' }}>28</span>
                    </IconButton>

                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={report} alt="Report" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                      <span style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '300', position: 'relative', top: '3px', left: '10px' }}>45</span>
                    </IconButton>
                  </div>

                  <div className="bookmarkrs d-flex align-items-center">
                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={upload} alt="Upload" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                    </IconButton>
                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={save} alt="Save" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>

            <div className="posts-section">
              <div className="post">
                <div className="post-header mb-4">
                  <div className="post-author">
                    <Avatar src={company} />
                    <div className="post-author-info">
                      <Typography variant="subtitle1" sx={{ fontSize: '16px', fontFamily: 'Poppins, Medium', fontWeight: '500' }}>Glow Beauty</Typography>
                      <Typography variant="body2" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', color: '#63676A' }}>@glowbeauty</Typography>
                    </div>
                  </div>
                  <IconButton sx={{ border: '2px solid black', width: '26px', height: '26px', color: 'black' }}><MoreHoriz /></IconButton>
                </div>
                <Typography variant="body2" className="post-content" style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: 'normal' }}>
                  Indulge in the opulent serenity of our skincare product, drwaing inspiration from peaceful sanctuaries nestled in verdant landscapes and clam waters. Awaken your innate beauty with the primium selection from our beauty brand.{!showMore && (
                    <>
                      <span> </span>
                      <Button variant="text" sx={{ fontSize: '12px', padding: '0', minWidth: 'unset', color: '#000', fontWeight: '600', fontFamily: 'Poppins, regular' }} onClick={handleShowMore}>
                        More.
                      </Button>
                    </>
                  )}
                </Typography>
                <img src={flowers} alt="Flowers" className="post-image" />
                <div className="post-interactions d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                  <div className="feed-icon d-flex align-item-center" style={{ gap: '15px' }}>
                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={likes} alt="Like" style={{ width: '20px', height: '20px' }} />
                      <span style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '300', position: 'relative', top: '3px', left: '10px' }}>15K</span>
                    </IconButton>

                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={comment} alt="Comment" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                      <span style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '300', position: 'relative', top: '3px', left: '10px' }}>28</span>
                    </IconButton>

                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={report} alt="Report" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                      <span style={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '300', position: 'relative', top: '3px', left: '10px' }}>45</span>
                    </IconButton>
                  </div>

                  <div className="bookmarkrs d-flex align-items-center">
                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={upload} alt="Upload" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                    </IconButton>
                    <IconButton sx={{ display: 'flex', alignItems: 'center' }}>
                      <img src={save} alt="Save" style={{ width: '20px', height: '20px', position: 'relative', top: '2px' }} />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>

            <div className="create-posts-section">
              <div className="create-posts">
                <img src={createposts} alt="Creating Poosts" />
                <h2>hasn't posted yet</h2>
                <Link sx={{ fontSize: '15px', fontFamily: 'Poppins, regular', fontWeight: '400', textDecoration: 'none', color: '#9A00A9', cursor: 'pointer'}}>Create a Post</Link>
              </div>
            </div>
          </Grid>

          {/* Right Sidebar (Suggested for You) */}
          <Grid item xs={3}>
            <div className="right-sidebar">
              <div className="search-bar mb-4">
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  className="custom-search-bar"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="sidebar-image mb-4">
                <img src={ads} alt="Sidebar-Image" className="sidebar-img" />
              </div>

              <div className="suggestions-section">
                <Typography variant="h6">Suggested for you</Typography>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={jutus} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Justus_Everett</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@justus_everett</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={billy} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Biily_Williams</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@billy_williams</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={juile} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Juile Bates</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@juilebeates</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={hana} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Hana Marshall</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@hanamarshall</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={kelvin} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Kelvin Leon</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@kelvinleon</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>
                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={roy} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Roy Benton</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@roy_benton</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={neol} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Neol_Hunt</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@neol_hunt</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={willow} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Willow Russell</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@willowrussell</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={karis} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Karis Gray</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@karis_gray</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={lillian} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Lillian Neal</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@lillianneal</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={sam} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Sam Hooper</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@samhooper</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={kiran} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Kiran Brooks</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@kiran_brooks</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={emerson} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Emerson Copeland</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@emerson_copeland</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={logan} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Logan Dunlap</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@logandunlap</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <div className="suggested-user mb-3">
                  <div className="user-detail">
                    <Avatar src={isari} />
                    <div className="user-info">
                      <Typography sx={{ fontSize: '16px', fontFamily: 'Poppins, Regular', fontWeight: '500' }}>Isai Boyer</Typography>
                      <Typography className='username' style={{ fontSize: '12px', fontFamily: 'Poppins, Regular', fontWeight: '500', color: '#63676A' }}>@isaiboyer</Typography>
                    </div>
                  </div>
                  <Button variant="outlined" className="following-btn" sx={{ fontSize: '14px', fontFamily: 'Poppins, Regular', fontWeight: '500' }} >Follow</Button>
                </div>

                <Typography
                  variant="body2"
                  color="primary"
                  style={{ cursor: 'pointer', textDecoration: 'none', fontFamily: 'Poppins, Medium', fontSize: '16px', fontWeight: '600', color: '#29008B' }}
                  onClick={() => {
                    // Add functionality to show more users here
                    console.log("Show more users clicked");
                  }}
                >
                  Show More
                </Typography>

              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
