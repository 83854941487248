// import logo from './logo.svg';
// import './App.css';
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginForm from "./components/loginform";
import "bootstrap/dist/css/bootstrap.min.css";
import SignUpForm from "./components/signupform";
import IndividualSignupForm from "./components/individualsignup";
import BusinessSignupForm from "./components/businesssignup";
import SocialMedias from "./components/socials";
import HomePage from "./components/homepage";
import SocialMedia from "./components/socialmedia";
import ResetPassword from "./components/resetpassword";
import ResetNewPassword from "./components/newpassword";
import InterestsForm from "./components/your-intereste";
import ProfilePage from "./components/individualsocial";
import BusinessProfilePage from "./components/businesssocial";
import AboutUs from "./components/aboutus";
import HelpCenter from "./components/helpcenter";
import WorkSpace from "./components/workspace";
import MarketPlace from "./components/marketplace";
import NewsChannel from "./components/newschannel";
import WhatsNew from "./components/whatsnew";
import Blog from "./components/blog";
import Revolutionizing from "./components/revolutionizing";
import ImpactFul from "./components/impactful";
import Investors from "./components/investors";
import TermsOfService from "./components/termsofservice";
import PrivacyPolicy from "./components/privacypolicy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUserProfile } from "./context/profile.contex";

function Routers() {
  const { user, isLoading, error, checkUserProfile } = useUserProfile();

  useEffect(() => {
    checkUserProfile();
  }, [checkUserProfile]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/socialmedia" element={<SocialMedia />} />
      <Route path="/loginform" element={<LoginForm />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/newpassword" element={<ResetNewPassword />} />
      <Route path="/signup" element={<SignUpForm />} />
      <Route path="/individualsignup" element={<IndividualSignupForm />} />
      <Route path="/businesssignup" element={<BusinessSignupForm />} />
      <Route path="/socials" element={<SocialMedias />} />
      <Route path="/your-intereste" element={<InterestsForm />} />
      <Route path="/individualsocial/:userId" element={<ProfilePage />} />
      <Route path="/businesssocial" element={<BusinessProfilePage />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/helpcenter" element={<HelpCenter />} />
      <Route path="/workspace" element={<WorkSpace />} />
      <Route path="/marketplace" element={<MarketPlace />} />
      <Route path="/newschannel" element={<NewsChannel />} />
      <Route path="/whatsnew" element={<WhatsNew />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/revolutionizing" element={<Revolutionizing />} />
      <Route path="/impactful" element={<ImpactFul />} />
      <Route path="/investors" element={<Investors />} />
      <Route path="/termsofservice" element={<TermsOfService />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default Routers;
