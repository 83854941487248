import React, { useState } from 'react';
import { Button, Typography, Box, TextField, IconButton, InputAdornment, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/signupform.css';
import axios from 'axios';
import LogoImg from './logo';
import resetpassword from '../img/resetpassword.png';

export default function ResetNewPassword() {
    const { register, handleSubmit, formState: { errors }, } = useForm();
    const [shownewPassword, setShowNewPassword] = useState(false);
    const [showconfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email; // Get email from the navigation state
    // const isMount = useRef(false);

    const handleClickShowNewPassword = () => {
        setShowNewPassword((prev) => !prev);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((prev) => !prev);
    };

    const onSubmit = async (data) => {
        const { password, confirmPassword } = data;

        // console.log("password !== confirmPassword",data)
        // console.log("Submitted Data:", data);

        // Check if passwords match
        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        try {
            const form = new FormData()
            // console.log("email",email);
            form.append("email",email);
            form.append("newPassword",password)
            // Make API call to reset password
            const response = await axios.patch('http://192.168.0.112:8081/api/user/reset-password', form);

            if (response.status === 200) {
                // Navigate to success page
                navigate('/password-reset-success');
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            // alert('Failed to reset password. Please try again.');
        }
    };

    return (
        <>
            <div className="container">
                <LogoImg />
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="account">
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ flex: 1, paddingTop: '20px' }}>
                                    <Typography variant="h2" component="h2" sx={{ fontWeight: '700', fontSize: '45px', fontFamily: 'Poppins, sans-serif', mb: 2 }}>
                                        {/* {accountType ? `${accountType} Reset Password` : 'Reset Password'} */}Create New Password
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 5, fontSize: '14px', fontFamily: 'Poppins, sans-serif' }}>
                                        Please choose a password that hasn't been used before. Must be at least 8 characters.
                                    </Typography>

                                    <TextField
                                        label="Set a Password"
                                        type={shownewPassword ? 'text' : 'password'}
                                        required
                                        sx={{
                                            mb: 2,
                                            '& .MuiOutlinedInput-root': { borderRadius: '30px' },
                                            '& .MuiInputLabel-asterisk': { color: 'red' },
                                        }}
                                        InputProps={{
                                            style: { borderRadius: '30px', backgroundColor: '#F9F9F9' },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowNewPassword}>
                                                        {shownewPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        {...register('password', {
                                            required: 'Password is required',
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                                                message: 'Password must have 8 characters, uppercase, lowercase, number, and special character',
                                            },
                                        })}
                                        error={!!errors.password}
                                        helperText={errors.password ? errors.password.message : ''}
                                    />

                                    <TextField
                                        label="Confirm new Password"
                                        type={showconfirmPassword ? 'text' : 'password'}
                                        name="confirmPassword"
                                        required
                                        sx={{
                                            mb: 2,
                                            '& .MuiOutlinedInput-root': { borderRadius: '30px' },
                                            '& .MuiInputLabel-asterisk': { color: 'red' },
                                        }}
                                        InputProps={{
                                            style: { borderRadius: '30px', backgroundColor: '#F9F9F9' },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClickShowConfirmPassword}>
                                                        {showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        fullWidth
                                        {...register('confirmPassword', {
                                            required: 'Password is required',
                                            pattern: {
                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                                                message: 'Password must have 8 characters, uppercase, lowercase, number, and special character',
                                            },
                                        })}
                                        error={!!errors.confirmPassword}
                                        helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                                    />

                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ mt: 2, borderRadius: '30px', height: '50px' }}
                                        onClick={handleSubmit(onSubmit)}
                                        style={{ background: 'linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)' }}
                                    >
                                        Reset Password
                                    </Button>

                                </Box>
                            </Box>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-img">
                            <img src={resetpassword} alt="Reset Password" style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
