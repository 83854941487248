import React from "react";
import { Avatar, Button, Typography, Link } from "@mui/material";
import johntomas from "../img/jhontomas.png";
import createpost from "../img/createpost.png";
import settings from "../img/settings.png";
import saved from "../img/saved.png";
import profiles from "../img/profiles.png";
import page from "../img/page.png";
import monetize from "../img/monetize.png";
import live from "../img/live.png";
import group from "../img/group.png";
import followingfollowers from "../img/followingfollowers.png";
import freindrequest from "../img/freindrequest.png";
import reels from "../img/reels.png";
import video from "../img/video.png";
import { useUserProfile } from "../context/profile.contex";
import { useNavigate } from "react-router-dom";

export default function LeftSide() {
  const { user, isLoading, error, checkUserProfile } = useUserProfile();

  const navigate = useNavigate();

  const handleOnClick = () => {

    return navigate(`/individualsocial/${user.userId}`);
  }

  return (
    <>
      <div className="sidebar">
        <div className="user-info d-flex align-items-center">
          <Avatar
            src={`${user?.profilePicture}?${new Date().getTime()}`}
            alt="John Tomas"
            sx={{ width: 56, height: 56, mr: 2 }}
          />
          <div className="usertitle" onClick={handleOnClick} style={{ cursor: 'pointer' }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: 14,
                fontFamily: "poppins",
                fontWeight: 400,
                color: "#000",
              }}
            >
              {user?.fullName}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: 12,
                fontFamily: "poppins",
                fontWeight: 200,
                color: "#000",

              }}
            >
              @{user?.userName}
            </Typography>
          </div>
        </div>
        <Link to="/create-post" className="create-post-link">
          {" "}
          {/* Specify the path to navigate */}
          <div className="create-post">
            <div className="creating-post">
              <img
                src={createpost}
                alt="Create Post"
                style={{ width: "100%" }}
              />
            </div>
            <div className="create-text">
              <h4>Create a Post</h4>
            </div>
          </div>
        </Link>
        <div className="sidebar-links">
          <div className="sidebar-link-title">
            <h4>Explore</h4>
          </div>
          {[
            { img: reels, text: "Reels" },
            { img: video, text: "Video" },
            { img: freindrequest, text: "Friends Request" },
            { img: followingfollowers, text: "Following & Followers" },
            { img: saved, text: "Saved" },
            { img: group, text: "Group" },
            { img: page, text: "Page" },
            { img: live, text: "Live Streams" },
            { img: monetize, text: "Monetize" },
            { img: profiles, text: "Profile" },
            { img: settings, text: "Settings" },
          ].map((link, index) => (
            <Button
              key={index}
              className="sidebar-link-button"
              sx={{ textTransform: "none" }}
            >
              <img
                src={link.img}
                alt={link.text}
                className="sidebar-link-icon"
              />
              {link.text}
            </Button>
          ))}
        </div>
      </div>
    </>
  );
}
