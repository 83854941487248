import React, {  } from 'react';
import { Button, TextField, Typography, Link, Box, } from '@mui/material';
import picture from '../img/picture.png';
import uploadpicture from '../img/uploadpicture.png';
import { useForm } from 'react-hook-form';
import '../css/loginform.css';
import signup from '../img/signup.png';
import 'bootstrap/dist/css/bootstrap.min.css';
// import SignUpForm from './signupform';
import { useNavigate } from 'react-router-dom';
import LogoImg from './logo';

export default function BusinessSignupForm() {

    const { handleSubmit, } = useForm();
    const navigate = useNavigate();

    // Form Submission Handler
    const onSubmit = (data) => {
        console.log('Form Data:', data);
        navigate('/socials'); // Use navigate to go to socials page
    };

    const handleSkipNowClick = () => {
        navigate('/socials'); // Navigate to Social page
      };

    return (
        <>
            <div className='container'>
                <LogoImg />
                <div className='row'>
                    <div className="col-lg-6 col-md-6">
                        <div className="form">
                            <Box sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                <div className='title mb-5 py-5'>
                                    <Typography variant="h1" component="h1" sx={{ fontWeight: '700', fontSize: '45px', fontFamily: 'poppins, semiBold' }}>
                                        Business Sign Up
                                    </Typography>
                                    <Typography variant="p" sx={{ mb: 4, fontFamily: 'poppins', fontSize: '14px' }}>
                                        Other information
                                    </Typography>
                                </div>

                                {/* Picture Upload */}
                                <img src={picture} alt="google" width={50} height={50} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ ml: 3 }}
                                    style={{ borderRadius: '30px', height: '47px', fontFamily: 'poppins', fontWeight: '400', fontSize: '14px', background: 'linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)' }}
                                    type="submit"
                                >
                                    <img src={uploadpicture} alt="uploadpicture" width={30} height={30} style={
                                        { padding: 5 }
                                    } />
                                    Upload a Picture
                                </Button>

                                {/* Information */}
                                <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '450px', marginTop: '50px' }}>
                                    {/* Phone Number Field */}
                                    <TextField
                                        label="Phone Number"
                                        variant="outlined"
                                        InputProps={{
                                            style: { borderRadius: '30px', backgroundColor: '#F9F9F9', '& .MuiInputLabel-asterisk': { color: 'red', }, },
                                        }}
                                    />

                                    {/* Address Field */}
                                    <TextField
                                        label="Address"
                                        variant="outlined"
                                        InputProps={{
                                            style: { borderRadius: '30px', backgroundColor: '#F9F9F9' },

                                        }}
                                    />

                                    {/* About Company Field */}
                                    <TextField
                                        label="About Company"
                                        variant="outlined"
                                        InputProps={{
                                            style: { borderRadius: '30px', backgroundColor: '#F9F9F9', },

                                        }}
                                    />                                   

                                    {/* Operating Hours Field */}
                                    <TextField
                                        label="Operating Hours"
                                        variant="outlined"
                                        InputProps={{
                                            style: { borderRadius: '30px', backgroundColor: '#F9F9F9', },

                                        }}
                                    />

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3, mt: 1, flexDirection: 'row-reverse' }}>

                                        <Link href="/socials" Onclick={handleSkipNowClick} sx={{ fontSize: 14, fontFamily: 'poppins', fontWeight: 600, color: '#9A00A9', textDecoration: 'none' }}>
                                            Skip for Now
                                        </Link>
                                    </Box>

                                    {/* Submit Button */}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{ mb: 2 }}
                                        style={{ borderRadius: '30px', height: '47px', fontFamily: 'poppins', fontWeight: '600', fontSize: '18px', background: 'linear-gradient(90deg, #9A00A9 34%, #580097 77%, #29008B 100%)' }}
                                        type="submit"
                                    >
                                        Next
                                    </Button>
                                </form>
                            </Box>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="form-img ">
                            <img src={signup} alt="signup" style={{ width: '100%' }} />
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}


